export default {
  pantographs: {
    "Choose a specific trip & time": "Scegli un viaggio e un orario specifico",
    Last: "Ultimo",
    abnormalities: "Anomalie",
    Sections: "Sezioni",
    "City Name": "Nome della città",
    Railways: "Ferrovie",
    "Make a report": "Creare un rapporto",
    "Sort by": "Ordina per",
    Time: "Tempo",
    Status: "Stato",
    Speed: "Velocità",
    "No. of abnormalities": "Numero di anomalie",
    "Type/Total": "Tipo/Totale",
    "abnormality/Total": "Anomalia/Totale",
    "Shock Point": "Punto d'urto",
    Favorite: "Preferito",
    Total: "Totale",
    Type: "Tipo",
    found: "trovato",
    No: "No",
    New: "Nuovo",
    "Point status": "Stato del punto",
    "Go to check": "Vai a controllare",
    Created: "Creato",
    Ignore: "Ignora",
    "False alarm": "Falso allarme",
    Repaired: "Riparato",
    "Need repair": "Richiede riparazione",
    "NoNeed repair": "Nessuna riparazione necessaria",
    "Point Detail": "Dettaglio del punto",
    "Download Signal": "Scarica segnale",
    "Downloading...": "Download in corso...",
    "Not Requested": "Non richiesto",
    Requested: "Richiesto",
    "Request Seen": "Richiesta vista",
    "Not Recorded": "Non registrato",
    Received: "Ricevuto",
    "Video Requested": "Video richiesto",
    Requesting: "Richiedendo",
    "Request Video": "Richiedi video",
    "Checked points": "Punti controllati",
    "Carbon strip": "Striscia di carbonio",
    pantographs: "Pantografi",
    Pantovision: "Pantovision",
    "Sorry, this signal doesn't have data":
      "Spiacente, questo segnale non contiene dati",
  },
};
