export default {
  components: {
    "Start typing...": "Inizia a digitare...",
    "Map not loaded correctly. An unknown error occurred.":
      "La mappa non è stata caricata correttamente. Si è verificato un errore sconosciuto.",
    "Error loading maps": "Errore durante il caricamento delle mappe",
    "Loading...": "Caricamento...",
    "An error occurred in the map section. Our team has been notified.":
      "Si è verificato un errore nella sezione della mappa. Il nostro team è stato notificato.",
    "Fetching address...": "Recupero dell'indirizzo in corso...",
    "Address not available": "Indirizzo non disponibile",
    "Address information could not be retrieved from the backend.":
      "Le informazioni sull'indirizzo non possono essere recuperate dal backend.",
    "Error fetching address. Please check the backend configuration.":
      "Errore nel recupero dell'indirizzo. Controlla la configurazione del backend.",
    "No address available": "Nessun indirizzo disponibile",
    "No valid markers found.": "Nessun marcatore valido trovato.",
    "An unknown error occurred.": "Si è verificato un errore sconosciuto.",
    "Map not loaded correctly.": "La mappa non è stata caricata correttamente.",
    "No points found :(": "Nessun punto trovato :(",
    "Make an abnormality": "Crea un'anomalia",
    "Color Legend": "Legenda colori",
    "Selected abnormality": "Anomalia selezionata",
    "Abnormalities from the same signal": "Anomalie dallo stesso segnale",
    "Abnormalities from the same point": "Anomalie dallo stesso punto",
    "select item...": "Seleziona un elemento...",
  },
};
