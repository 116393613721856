export default {
  point_status_summary: {
    Checked: "Verificato",
    "Go to check": "Vai alla verifica",
    today: "oggi",
    "this week": "questa settimana",
    "this 2week": "questa 2ª settimana",
    month: "mesi",
    week: "settimana",
    "Need to attention": "Richiede attenzione",
    "See on map": "Vedi sulla mappa",
    Points: "Punti",
    Point: "Punto",
    "Need to repair": "Riparazione necessaria",
    "No Need to repair": "Nessuna azione richiesta",
    Repaired: "Riparato",
    year: "anno",
    Yearly: "Annuale",
    Monthly: "Mensile",
    Weekly: "Settimanale",
    "1Y": "1A",
  },
};
