export default {
  supervision: {
    "Acc data number": "Zugangsdatennummer",
    "Acceleration STD": "Beschleunigung STD",
    "Acceleration-Miss data": "Beschleunigungs-Miss-Daten",
    "Add a figure": "hinzufügen",
    "Amir filter": "Amir filter",
    "Device Name": "Gerätename",
    "Download Signals": "herunterladen",
    "Download Video": "Video herunterladen",
    "Figure property": "Figureneigenschaft",
    "Figure type": "Figurentyp",
    "Impact time": "Einwirkzeit",
    "Impact Signal": "Signal Importieren",
    "Import Video": "Importieren",
    "Make report": "Bericht schreiben",
    "Max comparison ratio": "Maximales Vergleichsverhältnis",
    "Not found map points": "Kartenpunkte nicht gefunden",
    "Not Recorded": "Nicht aufgenommen",
    "Not Requested": "Nicht angefordert",
    "One minute summary": "Eine Minute Zusammenfassung",
    "one minute video": "einminütiges Video",
    "Seen by device": "Gesehen vom Gerät",
    "Search to find abnormalities until": "Suche nach Anomalien bis",
    "Signal filter": "Signalfilter",
    "Solar panel": "Sonnenkollektor",
    "Sorry, this signal doesn't have data":
      "Tut mir leid, dieses Signal enthält keine Daten",
    "Train speed": "Zuggeschwindigkeit",
    "Trip information": "Reiseinformationen",
    "Wind turbine": "Windkraftanlage",
    "Window length": "Fensterlänge",
    "Window rms": "Fenster rms",
    "Zig-Zag": "Zickzack",
    Acceleration: "Beschleunigung",
    Backward: "Rückwärts",
    Cancel: "Absagen",
    Copied: "Kopiert",
    Copy: "Kopieren",
    "Point Location": "Punktstandort",
    "Open in Google Maps": "In Google Maps öffnen",
    CT: "CT",
    Date: "Datum",
    Download: "Herunterladen",
    "More Details": "Weitere Details",
    Autofit: "Automatische Anpassung",
    fails: "scheitert",
    Forward: "Nach vorne",
    Go: "gehen",
    "height (cm)": "Höhe (cm)",
    Height: "Höhe",
    in: "in",
    Kur: "Kur",
    Max: "Max",
    Min: "Min",
    Ok: "Ok",
    out: "aus",
    Outside: "Draußen",
    PANTOboard: "PANTOboard",
    Pause: "Pause",
    Play: "Spiel",
    Received: "Empfangen",
    Remove: "Entfernen",
    Rendering: "Rendern",
    Requested: "Angefordert",
    "Send to device": "An Gerät senden",
    Std: "Std",
    Temperature: "Temperatur",
    Thickness: "Dicke",
    Time: "Zeit",
    Video: "Video",
    "zigzag (cm)": "Zickzack- (cm)",
    "Point info": "Punktinfo",
    "Last status": "Letzter Status",
    "Possibility of a problem": "Möglichkeit eines Problems",
    "The shocks are severe": "Die Erschütterungen sind schwerwiegend",
    Previous: "Vorherige",
    Next: "Nächste",
    "Trip info": "Reiseinformationen",
    Device: "Gerät",
    "No abnormalities found in this time":
      "In dieser Zeit wurden keine Auffälligkeiten festgestellt",
    "History & Forecast": "Geschichte und Prognose",
    "Go to check": "Gehen Sie zur Überprüfung",
    Checked: "Geprüft",
    "Create new status": "Neuen Status erstellen",
    "Point status removed!": "Punktstatus entfernt!",
    "No action yet!": "Noch keine Aktion!",
    "Go Check": "Überprüfen Sie",
    "Need to Repair": "Muss repariert werden",
    "No Need to Repair": "Keine Reparatur nötig",
    Repaired: "Repariert",
    "Checking...": "Überprüfen...",
    Nothing: "Nichts",
    "Section Insulator": "Abschnitt-Isolator",
    "Junction (crossroad)": "Kreuzung",
    "Mast (tower)": "Mast (Turm)",
    Overlap: "Überlappung",
    Crossing: "Kreuzung",
    Clamp: "Klemme",
    Curve: "Kurve",
    "Turn out": "Ausfahrt",
    Other: "Andere",
    "Status history": "Statusverlauf",
    Type: "Typ",
    "Something Went Wrong! Please Try Again Later":
      "Etwas ist schiefgelaufen! Bitte versuchen Sie es später noch einmal",
    "Image has been uploaded successfully":
      "Bild wurde erfolgreich hochgeladen",
    "Image is uploaded": "Bild ist hochgeladen",
    "The maximum allowed size for uploaded images is 100 KB!":
      "Die maximal zulässige Größe für hochgeladene Bilder beträgt 100 KB!",
    "Image is deleted": "Bild wurde gelöscht",
    "Image has been deleted successfully": "Bild wurde erfolgreich gelöscht",
    "There is no point to add a status":
      "Es gibt keinen Punkt, um einen Status hinzuzufügen",
    "Point status has been added successfully":
      "Punktstatus wurde erfolgreich hinzugefügt",
    "Point status added": "Punktstatus hinzugefügt",
    "Make status": "Status festlegen",
    Ignore: "Ignorieren",
    "False alarm": "Fehlalarm",
    "Need to repair": "Muss repariert werden",
    "No need to repair": "Keine Reparatur erforderlich",
    "Scratched C.W": "Kratzende C.W",
    "Dropper rupture": "Abreißen des Abnehmers",
    "Dropper drop": "Abnehmerabfall",
    "Overhead drop": "Überkopf-Abfall",
    Tension: "Spannung",
    Joint: "Gelenk",
    Cantilever: "Ausleger",
    "Rail Fault": "Schienenfehler",
    Description: "Beschreibung",
    "point status": "Punktstatus",
    "fault category": "Fehlerkategorie",
    Upload: "Hochladen",
    History: "Geschichte",
    Future: "Zukunft",
    "Please select a figure type": "Bitte wähle einen Figurtyp aus",
    "Please select a figure option": "Bitte wähle eine Figurenoption aus",
    "No abnormalities found in this time :(":
      "Keine Unregelmäßigkeiten in dieser Zeit gefunden :(",
    "Point Information": "Punktinformationen",
    "prevSignal is not defined": "prevSignal ist nicht definiert",
    "nextSignal is not defined": "nextSignal ist nicht definiert",
    Creator: "Ersteller",
    "Please set location access allowed":
      "Bitte gewähren Sie den Standortzugriff",
    "Please find your location": "Bitte finden Sie Ihren Standort",
    "Please select a abnormality": "Bitte wählen Sie eine Unregelmäßigkeit aus",
    Value: "Wert",
    Status: "Status",
    Score: "Punktzahl",
    Speed: "Geschwindigkeit",
    "Upload image failed!": "Bild-Upload fehlgeschlagen!",
    "Upload image succeeded!": "Bild-Upload erfolgreich!",
    "Score:": "Punktzahl:",
    "Time:": "Zeit:",
    more: "mehr",
    "Loading more...": "Mehr wird geladen...",
    "You can't remove this status!": "Du kannst diesen Status nicht entfernen",
    "No Abnormalities and statuses found in this time :(":
      "Keine Unregelmäßigkeiten und Status in dieser Zeit gefunden :(",
    "Contact Wire Information": "Kontaktleitung Informationen",
    "Video is deleted": "Video wurde gelöscht",
    "Video has been deleted successfully": "Video wurde erfolgreich gelöscht",
    "Selected time frame:": "Ausgewählter Zeitraum",
    "Choose checked type:": "Gewählten Typ auswählen:",
    "Checked description:": "Beschriebene Auswahl:",
    "OHL Feature:": "OHL Funktion",
    "Can you see a defect?": "Können Sie einen Defekt sehen?",
    Yes: "Ja",
    No: "Nein",
    "Not sure": "Nicht sicher",
    "Report OHL": "Melden OHL",
    "The video uploaded successfully":
      "Das Video wurde erfolgreich hochgeladen.",
    "Sorry, this video is not uploading":
      "Entschuldigung, dieses Video wird nicht hochgeladen.",
    "The request video send successfully.":
      "Das angeforderte Video wurde erfolgreich gesendet.",
    "You don't have access to see videos": "Du hast keinen Zugriff auf Videos.",
    "Previous Frame": "Vorheriges Bild",
    "Next Frame": "Nächstes Bild",
    "Video Not Recorded": "Video nicht aufgezeichnet",
    "The Video is Not Accessible... Code: NR":
      "Das Video ist nicht zugänglich... Code: NR,",
    "Video Not Requested": "Video nicht angefordert",
    "Request Seen by device": "Anfrage vom Gerät gesehen",
    "Video Corrupted": "Video beschädigt",
    "The Video is Not Accessible... Code: VC":
      "Das Video ist nicht zugänglich... Code: VC",
    "Request Sent to device": "Anfrage an Gerät gesendet",
    Processing: "Verarbeitung",
    "Due to the limitations of the device, we can only have the video available for up to two weeks.":
      "Aufgrund der Einschränkungen des Geräts können wir das Video nur für bis zu zwei Wochen zur Verfügung stellen.",
    "we don't have highlight abnormality":
      "Wir haben keine Hervorhebungsanomalie",
    "we don't have signal": "Wir haben kein Signal",
    "we don't have point": "Wir haben keinen Sinn",
    "You don’t have access to pantovision":
      "Sie haben keinen Zugriff auf Pantovision",
    "You don't have access to pantovision":
      "Du hast keinen Zugriff auf Pantovision.",
    "upload video": "Video hochladen",
    "video request": "Videoanfrage",
    "Video Request": "Videoanfrage",
    "Create status": "Status erstellen",
    goToCheck: "Zur Überprüfung gehen",
    checked: "Geprüft",
    "Point status history": "Punktstatusverlauf",
    looked: "Angesehen",
    "No need to repair yet": "Noch keine Reparatur erforderlich",
    "User checked": "Benutzer überprüft",
    "Visual inspection Checked": "Sichtprüfung durchgeführt",
    "Loading...": "Lädt...",
    "Detailed view of the": "Detaillierte Ansicht der",
    "signal data": "Signaldaten",
    "Use your mouse scroll to zoom in":
      "Verwenden Sie das Mausrad zum Hineinzoomen",
    "Zoom out": "Herauszoomen",
    "Zoom in": "Hineinzoomen",
    Delete: "Löschen",
    "Your report will be created": "Ihr Bericht wird erstellt",
    "You can see the status of your report from the notifications bar.":
      "Sie können den Status Ihres Berichts in der Benachrichtigungsleiste sehen.",
    "Setup report": "Bericht einrichten",
    "Report name": "Berichtsname",
    "Report type": "Berichtstyp",
    Pantograph: "Pantograph",
    Point: "Punkt",
    "Page size": "Seitengröße",
    "Project Information": "Projektinformationen",
    "Pointdetail QR code": "Punktdetail QR-Code",
    "Pantovision QR code": "Pantovision QR-Code",
    "Location QR code": "Standort QR-Code",
    "Generated a report for selected catenary points ,<br /> selected points":
      "Ein Bericht für die ausgewählten Oberleitungspunkte wurde erstellt, <br /> ausgewählte Punkte",
    "Select between 1 and 50 points . Higher points counts many impacts performance":
      "Wählen Sie zwischen 1 und 50 Punkten. Höhere Punktzahlen wirken sich stark auf die Leistung aus.",
    "Report name*": "Berichtname*",
    description: "Beschreibung",
    "Download PDF": "PDF herunterladen",
    "Error loading map": "Fehler beim Laden der Karte",
    "Please select an abnormality": "Bitte wählen Sie eine Abnormalität aus",
    "Delete line": "Linie löschen",
    Company: "Unternehmen",
    Increasing: "Steigend",
    Decreasing: "Senkend",
    "Line number": "Liniennummer",
    "Start tower number": "Start-Turm Nummer",
    "Health status": "Gesundheitsstatus",
    "Maintenance status": "Wartungsstatus",
    success: "Erfolg",
    "Point is": "Punkt ist",
    "Point '{{streetName}}' is Unarchived and will appear in the Catenary points list.":
      "Punkt '{{streetName}}' ist nicht archiviert und wird in der Kettenpunkte-Liste erscheinen.",
    "Point '{{streetName}}' is Archived and won't appear in the Catenary points list. You can find it using the sort feature.":
      "Punkt '{{streetName}}' ist archiviert und wird nicht in der Kettenpunkte-Liste erscheinen. Sie können ihn mit der Sortierfunktion finden.",
    "Point is {{action}}": "Punkt ist {{action}}",
    Archived: "Archiviert",
    Unarchived: "Nicht archiviert",
    archived: "archiviert",
    unarchived: "nicht archiviert",
    error: "Fehler",
    cannot_archive_or_unarchive:
      "Du kannst Punkt '{{action}}' nicht archivieren!",
    "You can't": "Du kannst nicht",
    "this point!": "diesen Punkt!",
    "Go check": "Überprüfen Sie es",
  },
};
