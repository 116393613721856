export default {
  widgets: {
    "Widget management": "Widget Management",
    "New abnormality": "Neues Anomalie",
    "Trigger alarms": "Alarme auslösen",
    "Catenary points summary": "Zusammenfassung der Oberleitungskontrollpunkte",
    Reporting: "Bericht",
    "Points status": "Punktestand",
    "Device on trains": "Gerät auf dem Zug",
    "Calendar & trips": "Datum & Fahrten",
    "User activity": "Benutzeraktivität",
    Pantographs: "Stromabnehmer",
    "Data to server log": "Daten zum Serverprotokoll",
    "Add widget": "Widget hinzufügen",
    "Remove widget": "Widget entfernen",
    Cancel: "Abbrechen",
    Ok: "Ok",
    "Imported abnormality": "Importierte Anomalie",
    Weekly: "Wöchentlich",
    Yearly: "Jährlich",
    Daily: "Täglich",
    Monthly: "Monatlich",
    "Something went wrong": "Etwas ist schief gelaufen",
    "The widget could not be removed.":
      "Das Widget konnte nicht entfernt werden.",
    "widget added": "Widget hinzugefügt",
    "The widget added successfully.":
      "Das Widget wurde erfolgreich hinzugefügt.",
    "widget removed": "Widget entfernt",
    "The widget removed successfully.": "Das Widget wurde erfolgreich entfernt",
    "Please select a widget to add":
      "Bitte wählen Sie ein Widget aus, um es hinzuzufügen",
    "You don't have access to add widgets":
      "Sie haben keinen Zugriff, um Widgets hinzuzufügen",
    Removing: "Entfernen",
    Add: "Hinzufügen",
    Click: "Klicken",
    click: "klicken",
  },
};
