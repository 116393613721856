export default {
  pantovision: {
    Pantographs: "Pantografi",
    Point: "Punto",
    Abnormalities: "Anomalie",
    "Sort by": "Ordina per",
    Sort: "Ordina",
    Date: "Data",
    Time: "Tempo",
    Speed: "Velocità",
    Temperature: "Temperatura",
    "std/STD": "std/STD",
    Kurtosis: "Curtosi",
    Max: "Max",
    Min: "Min",
    Abnormality: "Anomalia",
    Type: "Tipo",
    Total: "Totale",
    "Device Name": "Nome del dispositivo",
    Temp: "Temp",
    "Point status removed": "Stato del punto rimosso",
    "Point status successfully removed!":
      "Stato del punto rimosso con successo!",
    "Go to check": "Vai a controllare",
    Checked: "Controllato",
    Created: "Creato",
    "No status has been added for this pantograph":
      "Nessuno stato è stato aggiunto per questo pantografo",
    "Visual inspection checked": "Ispezione visiva verificata",
    "User checked": "Utente verificato",
    Ignore: "Ignora",
    "False alarm": "Falso allarme",
    "Need to repair": "Necessita di riparazione",
    "No need to repair yet": "Nessuna riparazione necessaria per ora",
    Repaired: "Riparato",
    "pantograph status removed!": "Stato del pantografo rimosso!",
    History: "Cronologia",
    "Pantograph status history": "Cronologia dello stato del pantografo",
    "Point status added": "Stato del punto aggiunto",
    "Point status has been added successfully":
      "Lo stato del punto è stato aggiunto con successo",
    Copy: "Copia",
    Copied: "Copiato",
    Value: "Valore",
    "History & Forecast": "Cronologia e previsioni",
    "No abnormalities found in this time":
      "Nessuna anomalia trovata in questo periodo",
    "Share this page": "Condividi questa pagina",
    "Make report": "Crea un rapporto",
    "Abnormality property": "Proprietà dell'anomalia",
    From: "Da",
    To: "A",
    "Show All": "Mostra tutto",
    "User Checked": "Utente verificato",
    "Visual inspection Checked": "Ispezione visiva verificata",
    "Checking list": "Lista di controllo",
    OK: "OK",
    Cancel: "Annulla",
    "This point checked at": "Questo punto controllato a",
    "Is this point a special": "Questo punto è speciale",
    "like a joint or a normal overlap":
      "come un giunto o una sovrapposizione normale",
    Yes: "Sì",
    No: "No",
    "Is there any fault": "C'è qualche difetto",
    "Was it repaired": "È stato riparato",
    "Point Description": "Descrizione del punto",
    "Problem Description": "Descrizione del problema",
    Why: "Perché",
    How: "Come",
    Select: "Seleziona",
    Nuch: "No",
    Suspention: "Sospensione",
    "Create new status": "Crea un nuovo stato",
    "Carbon strip wearing": "Usura della striscia di carbonio",
    "Estimation of replacement time": "Stima del tempo di sostituzione",
    "Suspension problem": "Problema alla sospensione",
    "Zig-Zag left": "Zig-Zag sinistra",
    "Zig-Zag right": "Zig-Zag destra",
    "Shock point": "Punto d'urto",
    Title: "Titolo",
    MarkAll: "",
    "All Notifications": "Tutte le notifiche",
    "Video Request": "Richiesta video",
    "Setup Report": "Configurazione rapporto",
    "Simulation Report": "Rapporto di simulazione",
    "Sent to device": "Inviato al dispositivo",
    Processing: "Elaborazione",
    "Seen by device": "Visto dal dispositivo",
    "Not recorded": "Non registrato",
    Corrupted: "Corrotto",
    Creating: "Creazione in corso",
    Unsuccessful: "Non riuscito",
    "In progress": "In corso",
    Successful: "Riuscito",
    Calculating: "Calcolando",
    "Error try again": "Errore, riprova",
    Done: "Fatto",
    "Device name:": "Nome del dispositivo:",
    "Report name:": "Nome del rapporto:",
    "Dynamic Model Calculation": "Calcolo del modello dinamico",
    "Condition 18 happened for the 21":
      "La condizione 18 si è verificata per il 21",
    time: "Tempo",
    "Video time": "Durata del video",
    Received: "Ricevuto",
    "Could not download video!": "Impossibile scaricare il video!",
    "Could not download pdf!": "Impossibile scaricare il PDF!",
    "Could not download file!": "Impossibile scaricare il file!",
    Model: "Modello",
    "Point Detail": "Dettaglio del punto",
    "Requested time": "Tempo richiesto",
    By: "Da",
    "Condition time": "Tempo della condizione",
    "Model name": "Nome del modello",
    FilterByType: "Filtra per tipo",
    Cancelled: "Annullato",
    "Requested Time": "Tempo richiesto",
    "Created Condition": "Condizione creata",
    "Estimated Remaining Time": "Tempo rimanente stimato",
    "Video Time": "Tempo video",
    "Condition Created": "Condizione creata",
    "Second Time Label": "Etichetta del secondo tempo",
    Requested: "Richiesto",
    Status: "Stato",
    "Trigger Condition": "Condizione di attivazione",
  },
};
