export default {
  point_status_summary: {
    Checked: "Geprüfte",
    "Go to check": "Zur Überprüfung anstehnd",
    today: "heute",
    "this week": "in dieser Woche",
    "this 2week": "in der 2Woche",
    month: "monate",
    week: "Woche",
    "Need to attention": "Bitte Beachten",
    "See on map": "Siehe Karte ",
    Points: "Punkte",
    Point: "Punkt",
    "Need to repair": "Reparatur erforderlich",
    "No Need to repair": "Keine Aktion erforderlich",
    Repaired: "Bearbeitet",
    year: "Jahr",
    Yearly: "Jährlich",
    Monthly: "Monatlich",
    Weekly: "Wöchentlich",
    "1Y": "1J",
  },
};
