export default {
  superpower: {
    Pointdetail: "Punktdetail",
    Supervision: "Beaufsichtigung",
    Pantovision: "Pantovision",
    "we don't have highlight abnormality":
      "Wir haben keine Hervorhebungsanomalie",
    "we don't have signal": "Wir haben kein Signal",
    "we don't have point": "Wir haben keinen Sinn",
    "You don’t have access to pantovision":
      "Sie haben keinen Zugriff auf Pantovision",
    "You don't have access to pantovision":
      "Du hast keinen Zugriff auf Pantovision.",
    "upload video": "Video hochladen",
    "video request": "Videoanfrage",
    "Video Request": "Videoanfrage",
    Delete: "Löschen",
    Loading: "Wird geladen",
    "Are you sure you want to delete this report?":
      "Möchten Sie diesen Bericht wirklich löschen?",
    "Delete Report": "Bericht löschen",
    "Sorry, A problem has occurred!":
      "Entschuldigung, es ist ein Problem aufgetreten!",
    "By clicking the Report button, the technical team will be informed of the problem and will fix it as soon as possible.":
      "Durch Klicken auf die Schaltfläche 'Bericht' wird das technische Team über das Problem informiert und wird es so schnell wie möglich beheben.",
    Report: "Bericht",
    Ignore: "Ignorieren",
    "By User": "Vom Benutzer",
    "Feature assigned": "Funktion zugewiesen",
    Feature: "Funktion",
  },
};
