export default {
  calendar_trips_page: {
    "You should choose a trip, first":
      "Per prima cosa, devi scegliere un viaggio",
    "Environment Temperature": "Temperatura ambiente",
    Average: "Media",
    Device: "Dispositivo",
    Date: "Data",
    Sections: "Sezioni",
    "City Name": "Nome della città",
    Railways: "Ferrovia",
    "Change trip": "Cambia viaggio",
    "Choose a trip": "Scegli un viaggio",
    Supervision: "Supervisione",
    "Download Signal": "Scarica segnale",
    "No Signal": "Nessun segnale",
    "Downloading...": "In scaricamento...",
    "Request Video": "Richiedi video",
    Received: "Ricevuto",
    "Not recorded": "Non registrato",
    Requested: "Richiesto",
    "Requesting...": "Richiesta in corso...",
    "Make a report": "Fai un rapporto",
    "Sort by": "Ordina per",
    Sort: "Ordina",
    "start->end": "inizio->fine",
    "end->start": "fine->inizio",
    "shock point": "Punto di shock",
    std: "ora",
    "First, you should choose a trip": "Prima, dovresti scegliere un viaggio",
    "Trip Information": "Informazioni sul viaggio",
    "please check mark a row(s) to make report":
      "Per favore, seleziona una o più righe per fare un rapporto",
    "Download List": "Scarica lista",
  },
};
