export default {
  panel_layout: {
    "403 — ACCESS DENIED": "403 — ZUGRIFF VERWEIGERT",
    "You currently does not have access to this page.":
      "Sie haben derzeit keinen Zugriff auf diese Seite.",
    "Please try again later.": "Bitte versuchen Sie es später erneut.",
    "It seems that your connection to the Internet has been interrupted. Please check your internet!":
      "Es scheint, dass Ihre Internetverbindung unterbrochen wurde. Bitte überprüfen Sie Ihr Internet!",
    "Fetching...": "Laden...",
    EmptyNotifications: "Sie haben keine Benachrichtigungen",
    "Panel Mode": "Panelmodus",
    Language: "Sprache",
    Theme: "Thema",
    "Internal Server Error": "Interner Serverfehler",
    "Bad Gateway": "Schlechtes Gateway",
    "Sorry! It is not possible to access the server at the moment.":
      "Entschuldigung! Der Server ist momentan nicht erreichbar.",
    "Sorry! There is a problem on the server side that our team is working on. Thank you for your patience.":
      "Entschuldigung! Es gibt ein Problem auf der Serverseite, an dem unser Team arbeitet. Vielen Dank für Ihre Geduld.",
    "Contact the": "Kontaktieren Sie den",
    "team support": "Team-Support",
    Time: "Zeit",
    "Error Code": "Fehlercode",
    Domain: "Domain",
    Company: "Unternehmen",
  },
};
