export default {
  pantovision: {
    Pantographs: "Pantographs",
    Point: "Punkt",
    Abnormalities: "Anomalien",
    "Sort by": "Sortieren nach",
    Sort: "Sortieren",
    Date: "Datum",
    Time: "Zeit",
    Speed: "Geschwindigkeit",
    Temperature: "Temperatur",
    "std/STD": "std/STD",
    Kurtosis: "Kurtosis",
    Max: "Max",
    Min: "Min",
    Abnormality: "Anomalie",
    Type: "Typ",
    Total: "Gesamt",
    "Device Name": "Gerätename",
    Temp: "Temp",
    "Point status removed": "Punktstatus entfernt",
    "Point status successfully removed!": "Punktstatus erfolgreich entfernt!",
    "Go to check": "Zur Überprüfung gehen",
    Checked: "Geprüft",
    Created: "Erstellt",
    "No status has been added for this pantograph":
      "Für diesen Stromabnehmer wurde kein Status hinzugefügt",
    "Visual inspection checked": "Sichtkontrolle geprüft",
    "User checked": "Benutzer überprüft",
    Ignore: "Ignorieren",
    "False alarm": "Falscher Alarm",
    "Need to repair": "Muss repariert werden",
    "No need to repair yet": "Noch kein Reparaturbedarf",
    Repaired: "Repariert",
    "pantograph status removed!": "Stromabnehmerstatus entfernt!",
    History: "Geschichte",
    "Pantograph status history": "Stromabnehmer-Statusverlauf",
    "Point status added": "Punktstatus hinzugefügt",
    "Point status has been added successfully":
      "Punktstatus wurde erfolgreich hinzugefügt",
    Copy: "Kopieren",
    Copied: "Kopiert",
    Value: "Wert",
    "History & Forecast": "Geschichte & Prognose",
    "No abnormalities found in this time":
      "Keine Anomalien in dieser Zeit gefunden",
    "Share this page": "Teile diese Seite",
    "Make report": "Bericht schreiben",
    "Abnormality property": "Anomalie-Eigenschaft",
    From: "Von",
    To: "Zu",
    "Show All": "Zeige alles",
    "User Checked": "Benutzer überprüft",
    "Visual inspection Checked": "Sichtprüfung Geprüft",
    "Checking list": "Check Liste",
    OK: "OK",
    Cancel: "Absagen",
    "This point checked at": "Dieser Punkt wurde überprüft",
    "Is this point a special": "Ist dieser Punkt ein besonderer",
    "like a joint or a normal overlap":
      "wie eine Fuge oder eine normale Überlappung",
    Yes: "Ja",
    No: "Nein",
    "Is there any fault": "Gibt es einen Fehler",
    "Was it repaired": "Wurde es repariert",
    "Point Description": "Punktbeschreibung",
    "Problem Description": "Problembeschreibung",
    Why: "Warum",
    How: "Wie",
    Select: "Wählen",
    Nuch: "Nein",
    Suspention: "Suspension",
    "Create new status": "Neuen Status erstellen",
    "Carbon strip wearing": "Kohlenstoffstreifen tragen",
    "Estimation of replacement time": "Schätzung der Ersatzzeit",
    "Suspension problem": "Aufhängungsproblem",
    "Zig-Zag left": "Zickzack nach links",
    "Zig-Zag right": "Zickzack rechts",
    "Shock point": "Schockpunkt",
    Title: "Titel",
    MarkAll: "",
    "All Notifications": "Alle Benachrichtigungen",
    "Video Request": "Videoanfrage",
    "Setup Report": "Bericht einrichten",
    "Simulation Report": "Simulationsbericht",
    "Sent to device": "An Gerät gesendet",
    Processing: "Wird verarbeitet",
    "Seen by device": "Vom Gerät gesehen",
    "Not recorded": "Nicht aufgezeichnet",
    Corrupted: "Beschädigt",
    Creating: "Wird erstellt",
    Unsuccessful: "Erfolglos",
    "In progress": "In Bearbeitung",
    Successful: "Erfolgreich",
    Calculating: "Wird berechnet",
    "Error try again": "Fehler, bitte versuchen Sie es erneut",
    Done: "Fertig",
    "Device name:": "Geräte name:",
    "Report name:": "Berichtsname:",
    "Dynamic Model Calculation": "Berechnung des dynamischen Modells",
    "Condition 18 happened for the 21": "Bedingung 18 trat für die 21 auf",
    time: "Zeit",
    "Video time": "Videodauer",
    Received: "Erhalten",
    "Could not download video!": "Video konnte nicht heruntergeladen werden!",
    "Could not download pdf!": "PDF konnte nicht heruntergeladen werden!",
    "Could not download file!": "Datei konnte nicht heruntergeladen werden!",
    Model: "Model",
    "Point Detail": "Punktdetails",
    "Requested time": "Angeforderte Zeit",
    By: "Von",
    "Condition time": "Bedingungszeit",
    "Model name": "Modellname",
    FilterByType: "Typ filtern",
    Cancelled: "storniert",
    "Requested Time": "Angeforderte Zeit",
    "Created Condition": "Bedingung erstellt",
    "Estimated Remaining Time": "Geschätzte verbleibende Zeit",
    "Video Time": "Videozeit",
    "Condition Created": "Bedingung erstellt",
    "Second Time Label": "Zweite Zeitbezeichnung",
    Requested: "Angefordert",
    Status: "Status",
    "Trigger Condition": "Auslösebedingung",
  },
};
