export default {
  calendar_trips: {
    "With gps": "Con gps",
    "With speed": "Con velocità",
    "With measured data": "Con dati misurati",
    "Device is ON": "Dispositivo acceso",
    "Device name": "Nome del dispositivo",
    "No. of Trips": "Numero di viaggi",
    "No trip found": "Nessun viaggio trovato",
    "Choose duration from calendar": "Scegli la durata dalla data",
    "See on map": "Vedi sulla mappa",
  },
};
