export default {
  views: {
    Cancel: "Stornieren",
    OK: "OK",
    "With gps": "Mit gps",
    "With speed": "Mit Geschwindigkeit",
    "With measured data": "Mit gemessenen Daten",
    "Device is ON": "Gerät ist EIN",
    "No trip found": "Keine Reise gefunden",
    "Device name": "Gerätename",
    "No. of Trips": "Anzahl Fahrten",
    "Choose duration from calendar": "Wählen Sie die Dauer aus dem Kalender",
    "Copied URL": "URL kopiert",
    "Copy URL": "URL kopieren",
    "For continue, you should login again":
      "Um fortzufahren, sollten Sie sich erneut anmelden",
    "Email address": "E-Mail-Addresse",
    Password: "Passwort",
    Login: "Anmeldung",
    Register: "Registrieren",
    "Something went wrong": "Etwas ist schief gelaufen",
    "The period of time not selected please select the time":
      "Der Zeitraum ist nicht ausgewählt. Bitte wählen Sie die Zeit aus",
    "Share This Page": "Diese Seite teilen",
  },
};
