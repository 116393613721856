export default {
  trigger: {
    "Trigger created": "Trigger erstellt",
    "Trigger created successfully": "Trigger erfolgreich erstellt",
    "Creating trigger failed": "Erstellen des Triggers fehlgeschlagen",
    "Condition one is required": "Bedingung eins ist erforderlich",
    "Validation Error": "Validierungsfehler",
    "Condition two is required When you choose and/or":
      "Bedingung zwei ist erforderlich, wenn Sie und/oder wählen",
    "Please select a device": "Bitte wählen Sie ein Gerät aus",
    "Please select an action": "Bitte wählen Sie eine Aktion aus",
    "Please write an email": "Bitte schreiben Sie eine E-Mail",
    "The conditions should not be equal to each other!":
      "Die Bedingungen sollten nicht gleich sein!",
    "All Points": "Alle Punkte",
    "At risk": "Gefährdet",
    Unhealthy: "Ungesund",
    "Need to attention": "Benötigt Aufmerksamkeit",
    If: "Wenn",
    Select: "Auswählen",
    "Email address": "E-Mail-Adresse",
    "Add a trigger": "Trigger hinzufügen",
    "Make a sensor trigger": "Sensor-Trigger erstellen",
    In: "In",
    Then: "Dann",
    Email: "E-Mail",
    "Created by": "Erstellt von",
    "Delete the trigger": "Trigger löschen",
    "Condition 1": "Bedingung 1",
    "Condition 2": "Bedingung 2",
    Action: "Aktion",
    "Date Time": "Datum Uhrzeit",
    "Trigger deleted": "Trigger gelöscht",
    "Trigger deleted successfully": "Trigger erfolgreich gelöscht",
    "Creating trigger failed!": "Erstellen des Triggers fehlgeschlagen!",
    "Something went wrong!": "Etwas ist schiefgelaufen!",
    "Are you sure you want to delete this trigger?":
      "Sind Sie sicher, dass Sie diesen Trigger löschen möchten?",
    "You can create a new trigger": "Sie können einen neuen Trigger erstellen",
    "using the top section": "mit dem oberen Abschnitt",
    Id: "ID",
    Connector: "Connector",
    Device: "Gerät",
    DateTime: "Datum Uhrzeit",
    Delete: "Löschen",
  },
};
