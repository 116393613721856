export default {
  simulation: {
    "Static modeling": "Statische Modellierung",
    "Static result": "Statische Ergebnisse",
    "Dynamic modeling": "Dynamische Modellierung",
    "Dynamic result": "Dynamische Ergebnisse",
    "Simulation saved successfully": "Simulation erfolgreich gespeichert",
    "Error saving simulation": "Fehler beim Speichern der Simulation",
    "You are only allowed to read the data": "Sie dürfen die Daten nur lesen",
    Setting: "Einstellung",
    "Share this page": "Teile diese Seite",
    Saving: "Speichern",
    Save: "Speichern",
    "Catenary Name": "Oberleitungsname",
    "Oh, you have not sent a static request yet!":
      "Oh, Sie haben noch keine statische Anfrage gesendet!",
    "First, go to static modeling and send a new request":
      "Gehen Sie zuerst zur statischen Modellierung und senden Sie eine neue Anfrage",
    "Go to static modeling": "Gehen Sie zur statischen Modellierung",
    "You can see the static configuration of your design as well as the required length/static force of each dropper":
      "Sie können die statische Konfiguration Ihres Designs sowie die erforderliche Länge/statische Kraft jedes Tropfers sehen",
    "Go to span view": "Gehe zu span Ansicht",
    "Go to full view": "Gehe zu vollansicht",
    "Go to heatmap": "Gehen Sie zur Heatmap",
    "Go to Analysis Section": "Gehen Sie zum Abschnitt Analyse",
    "Dropper number": "Dropper Anzahl",
    "Dropper force": "Dropper-Kraft",
    "Dropper elasticity": "Dropper-Elastizität",
    // Section: "Abschnitt",
    "Default catenary simulated": "Standardoberleitung simuliert",
    "Dropper length": "Dropper Länge ",
    Dropper: "Dropper",
    Force: "Gewalt",
    Elasticity: "Elastizität",
    Length: "Länge",
    "Download excel file": "Excel-Datei herunterladen",
    // Span: "Spanne",
    "Oh, you have not sent a dynamic request yet!":
      "Oh, Sie haben noch keine dynamische Anfrage gesendet!",
    "First, go to dynamic modeling and send a new request":
      "Gehen Sie zuerst zur dynamischen Modellierung und senden Sie eine neue Anfrage",
    "Go to dynamic modeling": "Gehen Sie zur dynamischen Modellierung",
    "Analysis Section": "Section Analyse",
    "Pantograph Moment": "Pantograph Moment",
    "Displacement (MM)": "Hubraum (MM)",
    "Velocity (M/S)": "Geschwindigkeit (M/S)",
    "Acceleration (M/S2)": "Beschleunigung (M/S2)",
    "Contact force (N)": "Kontaktkraft (N)",
    "Pantograph's head": "Pantograph's Kopf ",
    time: "Zeit",
    Backward: "Rückwärts",
    Pause: "Pause",
    Play: "Spiel",
    Forward: "Nach vorne",
    "STD of contact force (0 Hz to 5 Hz)=":
      "STD der Kontaktkraft (0 Hz bis 5 Hz)=",
    "STD of contact force (5 Hz to 20 Hz)=":
      "STD der Kontaktkraft (5 Hz bis 20 Hz)=",
    "STD of contact force (0 Hz to 20 Hz)=":
      "STD der Kontaktkraft (0 Hz bis 20 Hz)=",
    "STD (incl. low pass filter 20 Hz)=": "STD (inkl. Tiefpassfilter 20 Hz)=",
    "Actual maximum of contact force=":
      "Tatsächliches Maximum der Kontaktkraft=",
    "Actual minimum of contact force=":
      "Tatsächliches Minimum der Kontaktkraft=",
    "Percentage of loss of contact=": "Prozentsatz des Kontaktverlusts=",
    "Maximum support uplift=": "Maximaler Unterstützungsauftrieb =",
    "Uplift at support": "Uplift bei der Unterstützung",
    "Dropper Force": "Dropper-Kraft",
    Displacement: "Verschiebung",
    Acceleration: "Beschleunigung",
    Velocity: "Geschwindigkeit",
    Pantograph: "Pantograph",
    Max: "Max",
    Min: "Min",
    Distance: "Distanz",
    "Analysis section from end of": "Analyse section vom Ende des",
    to: "zu",
    Apply: "Anwenden",
    "Request for custom pantograph model":
      "Anfrage für kundenspezifisches Stromabnehmermodell",
    "Pantograph property": "Pantograph-Eigenschaft",
    "Manual input": "Manuelle Eingabe",
    "K contact (N/m)": "K-Kontakt (N/m)",
    "Auto input": "Automatische Eingabe",
    "moving load": "bewegte Last",
    "Force (N)": "Kraft (N)",
    "Dynamic Response": "Dynamische Antwort",
    "Load Dynamic Response": "Dynamische Antwort laden",
    Uplift: "Erheben",
    force: "Gewalt",
    "Back to modeling": "Zurück zum Modellieren",
    "train speed": "Zuggeschwindigkeit",
    "Mechanical Properties": "Mechanische Eigenschaften",
    "Oh sorry, this calculation can't be solved!":
      "Oh sorry, diese Rechnung kann nicht gelöst werden!",
    "The support team will contact you soon as possible.":
      "Das Support-Team wird sich schnellstmöglich mit Ihnen in Verbindung setzen.",
    "If there is something you can write in the chat section.":
      "Wenn es etwas gibt, können Sie im Chat-Bereich schreiben.",
    "Messenger Wire": "Messenger Kabel",
    "Messenger Wire Support": "Messenger Drahtunterstützung ",
    "Steady Arm": "Stabiler Arm",
    "Stitch Wire": "Stitch Kabel",
    "Contact Wire": "Contact Kabel",
    "Contact wire": "Contact Kabel",
    "The number of": "Die Zahl der",
    "overlap spans": "Spannweiten überlappen",
    between: "zwischen",
    and: "und",
    "is equal to": "ist gleich",
    Preview: "Vorschau",
    "First you should calculate static data":
      "Zuerst sollten Sie statische Daten berechnen",
    Calculate: "Berechnung",
    Projects: "Projekte",
    "Estimated calculation time": "Geschätzte Berechnungszeit",
    Cancel: "Absagen",
    "Calculation started": "Berechnung gestartet",
    "Request cancelled": "Anfrage abgebrochen",
    "Error happened!": "Fehler passiert!",
    "Request for VR 4D film": "Anfrage für VR 4D Film",
    Ok: "Ok",
    "*The damping of the other mode shapes will be considered based on the extrapolation of the assigned values":
      "*Die Dämpfung der anderen Eigenformen wird anhand der Extrapolation der zugeordneten Werte berücksichtigt",
    "Mass of the clamp to catenary wire (kg)":
      "Masse der Klemme zum Fahrdraht (kg)",
    "Validation Warning": "Validierungswarnung",
    "the clamp is so light or too heavy, check the technical data":
      "die Schelle zu leicht oder zu schwer ist, überprüfen Sie die technischen Daten",
    "Validation Error": "Validierungsfehler",
    "The weight of clamp can not be less than zero or more that 5kg":
      "Das Gewicht der Klemme darf nicht weniger als null oder mehr als 5 kg betragen",
    "Tension per wire (kN)": "Spannung pro Draht (kN)",
    "Young's modulus": "Elastizitätsmodul",
    "the Young's modulus in messenger wire is 90-120 kN/mm2 normally":
      "Der Elastizitätsmodul im Tragdraht beträgt normalerweise 90-120 kN/mm2",
    "the value is not valid, check the technical data of the cable":
      "der Wert ist ungültig, überprüfen Sie die technischen Daten des Kabels",
    "Mass per length (kg/m)": "Masse pro Länge (kg/m)",
    "the mass per unit length of this cable is 0.8-1.1 kg/m normally":
      "Die Masse pro Längeneinheit dieses Kabels beträgt normalerweise 0,8-1,1 kg/m",
    "Cross section": "Kreuzung",
    "the cross section of this cable is 30-40 mm2 normally":
      "Der Querschnitt dieses Kabels beträgt normalerweise 30-40 mm2",
    "Max frequency": "Maximale Frequenz",
    "*The maximum frequencies that will be considered in the simulation. The higher considered frequencies, the higher precision in results and the more required simulation time":
      "*Die maximalen Frequenzen, die in der Simulation berücksichtigt werden. Je höher die betrachteten Frequenzen, desto präziser die Ergebnisse und desto länger die benötigte Simulationszeit",
    "Number of steady arms": "Anzahl der stabilen Arme",
    "Number of steady arms are either 1 or 2!":
      "Anzahl der Lünette wahlweise 1 oder 2!",
    "the steady arm is so short or too long, check the technical data":
      "der Seitenhalter so kurz oder zu lang ist, überprüfen Sie die technischen Daten",
    "The length of steady arm can not be less than zero or more that 10":
      "Die Länge des Seitenarms darf nicht kleiner als null oder größer als 10 sein",
    "Mass per length (kg)": "Masse pro Länge (kg)",
    "the mass per unit length of steady arm is 0.6-1.1 kg/m normally":
      "Das Gewicht pro Längeneinheit des Seitenhalters beträgt normalerweise 0,6-1,1 kg/m",
    "Mass of clamp (kg)": "Masse der Schelle (kg)",
    "The damping of steady arm can not be negative or high value.":
      "Die Dämpfung des Seitenstabilisators darf nicht negativ oder hoch sein.",
    "Damping (N.s/m)": "Dämpfung (N.s/m)",
    "The damping of steady arm can not be more than 100 N.s/m":
      "Die Dämpfung des Seitenhalters darf nicht mehr als 100 N.s/m betragen",
    "Constant values": "Konstante Werte",
    "Stiffness (kN/m)": "Steifigkeit (kN/m)",
    "The stiffness of the support is either low or high":
      "Die Steifigkeit des Trägers ist entweder niedrig oder hoch",
    "The stiffness of the support can not be less than 0 or more than 10000, if the support is rigid, please check the bottom above":
      "Die Steifheit der Stütze darf nicht weniger als 0 oder mehr als 10000 betragen, wenn die Stütze starr ist, überprüfen Sie bitte unten oben",
    "Damping (Ns/m)": "Dämpfung (Ns/m)",
    "The damping of the support is either low or high":
      "Die Dämpfung der Stütze ist entweder gering oder hoch",
    "The damping of the support can not be less than 0 or more than 10000, if the support is rigid, please check the bottom above":
      "Die Dämpfung der Stütze darf nicht kleiner als 0 oder größer als 10000 sein, wenn die Stütze starr ist, überprüfen Sie bitte unten oben",
    "Load response": "Antwort laden",
    Fixed: "Fest",
    "Load dynamic response": "Dynamik laden",
    "the tension in messenger wire is 13-21kN normally":
      "Die Spannung im Tragseil beträgt normalerweise 13-21 kN",
    "This linear analytical simulation is not suitable for extremely high tension or super low tension cables":
      "Diese lineare analytische Simulation ist nicht für Kabel mit extrem hoher Spannung oder mit extrem niedriger Spannung geeignet",
    "the cross section of this cable is 80-120 mm2 normally":
      "der Querschnitt dieses Kabels beträgt normalerweise 80-120 mm2",
    "This changes applied on dropper that created with our default values":
      "Diese Änderungen wurden auf Dropper angewendet, die mit unseren Standardwerten erstellt wurden",
    "Stiffness and Damping": "Steifigkeit und Dämpfung",
    "A rigid connection between contact wire and messenger cable will be considered":
      "Eine starre Verbindung zwischen Fahrdraht und Tragseil wird berücksichtigt",
    "Young's modulus (kN/mm2)": "Elastizitätsmodul (kN/mm2)",
    "The weight of clamp can not be less than zero or more that 3kg":
      "Das Gewicht der Klemme darf nicht weniger als null oder mehr als 3 kg betragen",
    "Cross section (mm2)": "Querschnitt (mm2)",
    "the cross section of this cable is 5-50 mm2 normally":
      "der Querschnitt dieses Kabels beträgt normalerweise 5-50 mm2",
    "the mass per unit length of dropper is 0.6-0.2 kg/m normally":
      "Die Masse pro Längeneinheit des Tropfers beträgt normalerweise 0,6–0,2 kg/m",
    "Clamp on messenger wire (Kg)": "Klemme am Tragseil (Kg)",
    "Clamp on contact wire (Kg)": "Klemme am Fahrdraht (Kg)",
    "Using buckling theory": "Anwendung der Knicktheorie",
    "A spring with a bilinear stiffness between contact wire and messenger cable will be considered":
      "Betrachtet wird eine Feder mit bilinearer Steifigkeit zwischen Fahrdraht und Tragseil",
    "Max compression force (N)": "Maximale Kompressionskraft (N)",
    "Assign a value": "Weisen Sie einen Wert zu",
    "If you get the buckling threshold low, the calculation time would be too much. The High threshold, makes your dropper rigid":
      "Wenn Sie die Knickschwelle niedrig erhalten, wäre die Berechnungszeit zu lang. Die hohe Schwelle macht Ihre Pipette starr",
    "The dropper stiffness in tension can not be less than zero.":
      "Die Steifheit des Tropfers unter Spannung kann nicht kleiner als null sein.",
    Analytical: "Analytisch",
    "Stiffness in tension (N/m)": "Zugsteifigkeit (N/m)",
    "the dropper stiffness is normally 100kN/m in tension, check the technical data":
      "die Dropper-Steifigkeit beträgt normalerweise 100 kN/m auf Zug, überprüfen Sie die technischen Daten",
    "The dropper stiffness in tension can not be less than zero or more than 500kN/m":
      "Die Steifheit des Droppers unter Spannung darf nicht weniger als null oder mehr als 500 kN/m betragen",
    "Damping for compression (N.s/m)": "Dämpfung für Kompression (N.s/m)",
    "The damping of dropper should not be too small or too high":
      "Die Dämpfung des Tropfers sollte nicht zu klein oder zu hoch sein",
    "The damping of dropper cannot be negative or high value.":
      "Die Dämpfung des Tropfers darf nicht negativ oder hoch sein.",
    "Damping for tension (N.s/m)": "Dämpfung für Spannung (N.s/m)",
    "Stiffness for compression (N/m)": "Steifigkeit für Kompression (N/m)",
    "the dropper stiffness is normally less than 100 tension, check the technical data":
      "die Tropfersteifigkeit ist normalerweise kleiner als 100 Spannung, überprüfen Sie die technischen Daten",
    "Stiffness for tension (N/m)": "Steifigkeit für Zug (N/m)",
    "Add span": "Span hinzufügen",
    "(first span)": "(erste span)",
    "(last span)": "(letzte span)",
    "Stitch wire tension": "Stichdrahtspannung",
    "the tension of stitch wire is normally 10-40% of the tension of messenger cable":
      "Die Spannung des Heftdrahts beträgt normalerweise 10-40 % der Spannung des Tragseils",
    "the tension of stitch wire can not be zero or more than the tension of messenger cable":
      "Die Spannung des Stichdrahts darf nicht null oder höher sein als die Spannung des Tragseils",
    "the length of stitch wire can not be zero or more than the length of span":
      "Die Länge des Stichdrahts darf nicht null oder größer als die Spannweite sein",
    "normally 2-4 droppers located in stitch wire":
      "normalerweise 2-4 Tropfer im Heftdraht",
    "The messenger wire height can not be less than contact wire height":
      "Die Tragdrahthöhe darf nicht geringer sein als die Fahrdrahthöhe",
    "The catenary encumbrance is 1.2-1.8 normally":
      "Die Oberleitungsbelastung beträgt normalerweise 1,2-1,8",
    "The contact wire height can not be negative or zero or more than messenger cable height":
      "Die Fahrdrahthöhe darf nicht negativ oder null oder größer als die Tragseilhöhe sein",
    "consider the recommendation of EN 15273-2-2013":
      "Beachten Sie die Empfehlung der EN 15273-2-2013",
    "the length of span could not be negative or zero":
      "die Länge der Spanne konnte nicht negativ oder null sein",
    "Length of span is normally 27-63m":
      "Die Spannweite beträgt normalerweise 27-63 m",
    "Add Dropper": "Dropper hinzufügen",
    "The number of droppers cannot be less than zeros or too high":
      "Die Anzahl der Dropper darf nicht kleiner als Null oder zu hoch sein",
    "The stager can not be more than span length":
      "Der Stager darf nicht länger als Spannweite sein",
    "The stager is ±30 cm normally": "Der Stager beträgt normalerweise ±30 cm",
    "individual setting for steady arm":
      "individuelle Einstellung für Seitenständer",
    "Messenger wire stagger": "Messenger Wire Staffelung",
    "Contact wire stagger": "Fahrdraht versetzt",
    "New Catenary": "Neue Oberleitung",
    "Catenary Pool": "Oberleitungspool",
    "Catenary deleted successfully!": "Oberleitung erfolgreich gelöscht!",
    "Something went wrong!": "Etwas ist schief gelaufen!",
    "Catenary duplicated successfully!": "Oberleitung erfolgreich dupliziert!",
    "Catenary left successfully!": "Oberleitung erfolgreich verlassen!",
    "No catenaries found": "Keine Oberleitungen gefunden",
    "No selected": "Nein ausgewählt",
    CatenaryType: "Oberleitungstyp",
    Access: "Zugriff",
    Sample: "Probe",
    unknow: "unbekannt",
    "Created time": "Erstellte Zeit",
    "Updated time": "Zeit aktualisiert",
    "Created by": "Erstellt von",
    Permission: "Erlaubnis",
    Duplicate: "Duplikat",
    Leave: "Verlassen",
    Delete: "Löschen",
    "All Access": "alle Zugriff",
    Full: "Voll",
    Read: "Lesen",
    Write: "Schreiben",
    "All Catenary": "Alle Oberleitung",
    Stitched: "Genäht",
    Compound: "Verbindung",
    "View catenary": "Oberleitung ansehen",
    "Catenary created": "Oberleitung erstellt",
    "Catenary model": "Oberleitungsmodell",
    "Apply to all": "Bewerben Sie sich für alle",
    "New simple catenary": "Neue einfache Oberleitung",
    "New stitched catenary": "Neue genähte Oberleitung",
    "New compound catenary": "Neue zusammengesetzte Oberleitung",
    "Request for custom catenary": "Anfrage für benutzerdefinierte Oberleitung",
    "Search between catenaries by name":
      "Suche zwischen oberleitungen nach namen",
    "Stitched catenary": "Genähte Oberleitung",
    "The bilinear mode is not yet implemented":
      "Der bilineare Modus ist noch nicht implementiert",
    "Droppers cannot be positioned directly on the mast.":
      "Der Dropper darf sich nicht direkt am Mast befinden.",
    "The dropper can't be located on either previous dropper or out of considered span":
      "Der Dropper kann sich weder auf dem vorherigen Dropper noch außerhalb der betrachteten Spanne befinden",
    "Dropper Detail": "Dropper Detail",
    "Rigid dropper": "Steifer Tropfer",
    "Distance from left-side dropper": "Abstand vom linken Tropfer",
    "Dropper is either too close to or so far from the previous dropper.":
      "Dropper ist entweder zu nah oder zu weit entfernt vom vorherigen Dropper.",
    "The vertical position of contact wire":
      "Die vertikale Position des Fahrdrahts",
    "in the dropper location": "in der Dropper-Position",
    "in the dropper location (m)": "in der Dropper-Position (m)",
    "The presags of the contact wire in dropper points are less than 50mm normally":
      "Die Vorsprünge des Fahrdrahtes in den Dropper Points betragen normalerweise weniger als 50 mm",
    "The presags of the contact wire in dropper points can not be more than 200mm":
      "Die Vorsprünge des Fahrdrahtes in den Dropper Points dürfen nicht mehr als 200 mm betragen",
    "individual setting": "individuelle Einstellung",
    " A spring with a bilinear stiffness between contract wire and messenger cable will be considered":
      "Betrachtet wird eine Feder mit bilinearer Steifigkeit zwischen Zugdraht und Tragseil",
    "Bilinear stiffness spring for droppers":
      "Feder mit bilinearer Steifigkeit für Tropfer",
    "Elasticity of overhead contact line at the dropper points":
      "Elastizität der Oberleitung an den Hängepunkten",
    "something in static model is changed, you will need to recalculate if you want to see updated results":
      "Wenn sich etwas am statischen Modell geändert hat, müssen Sie eine Neuberechnung durchführen, wenn Sie aktualisierte Ergebnisse sehen möchten",
    "Add Section Insulator": "Streckenisolator hinzufügen",
    "Add Clamp": "Klemme hinzufügen",
    "Custom Property": "Benutzerdefinierte Eigenschaft",
    "Choose from library": "Aus der Bibliothek auswählen",
    "Section Insulator Detail": "Detail der Abschnittsisolatoren",
    "Project imported": "Projekt importiert",
    "You can see the imported project":
      "Sie können das importierte Projekt sehen",
    "Invalid parameters": "Ungültige Parameter",
    "You must pass this fields: type, model_type, name, tags, share_token, static_modeling, dynamic_modeling":
      "Sie müssen diese Felder übergeben: Typ, Modelltyp, Name, Tags, Freigabetoken, statische Modellierung, dynamische Modellierung",
    "You can only have 5 projects": "Sie können nur 5 Projekte haben",
    "If you want create more, please contact us":
      "Wenn Sie mehr erstellen möchten, kontaktieren Sie uns bitte",
    "Project import failed": "Projektimport fehlgeschlagen",
    "Please check your project data": "Bitte überprüfen Sie Ihre Projektdaten",
    "Import Project": "Projekt importieren",
    "If you want create more, contact us":
      "Wenn Sie mehr erstellen möchten, kontaktieren Sie uns",
    "Catenary name": "Fahrleitungsname",
    "Are you sure you want to delete this card?":
      "Sind Sie sicher, dass Sie diese Karte löschen möchten?",
    unknown: "Unbekannt",
    "Do you want to duplicate": "Möchten Sie duplizieren?",
    catenary: "Fahrleitung",
    "New AC simple catenary": "Neue AC einfache Fahrleitung",
    "New AC stitched catenary": "Neue AC genähte Fahrleitung",
    "New DC simple catenary": "Neue DC einfache Fahrleitung",
    "New DC stitched catenary": "Neue DC genähte Fahrleitung",
    "Newest created": "Neueste erstellt",
    "Oldest created": "Älteste erstellt",
    "Recently updated": "Kürzlich aktualisiert",
    "Search between catenaries by nam":
      "Suche zwischen Fahrleitungen nach Namen",
    "Please enter all fields": "Bitte füllen Sie alle Felder aus",
    "Something went wrong. Please try again":
      "Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut",
    "Project transfer completed successfully!":
      "Projektübertragung erfolgreich abgeschlossen!",
    "Transfer Project": "Projekt übertragen",
    Transfer: "Übertragung",
    Origin: "Ursprung",
    Destination: "Ziel",
    "404 - Not Found": "404 - Nicht gefunden",
    "Maybe the link is wrong or the catenary has been deleted":
      "Vielleicht ist der Link falsch oder die Fahrleitung wurde gelöscht",
    "Go to Simulations": "Zu den Simulationen gehen",
    "401 - No Access": "401 - Kein Zugriff",
    "You don't have access to this catenary":
      "Sie haben keinen Zugriff auf diese Fahrleitung",
    "Please try again later": "Bitte versuchen Sie es später erneut",
    "Something went wrong": "Etwas ist schiefgelaufen",
    "Save Changes": "Änderungen speichern",
    "Project Setting": "Projekteinstellungen",
    "Back to Catenary Pool": "Zurück zum Fahrleitungs-Pool",
    "Export project as JSON": "Projekt als JSON exportieren",
    "Error saving simulatio": "Fehler beim Speichern der Simulation",
    "Pantograph Identification": "Pantographen-Identifikation",
    "Start Identification": "Identifikation starten",
    "Model name must be alphanumeric and can include underscores or dashes":
      "Modellname muss alphanumerisch sein und Unterstriche oder Bindestriche enthalten",
    "Model name must be between 3 and 50 characters":
      "Modellname muss zwischen 3 und 50 Zeichen lang sein",
    Estimate: "Schätzung",
    mean: "Mittelwert",
    dynamic: "dynamisch",
    model: "Modell",
    detail: "Detail",
    Section: "Abschnitt",
    Span: "Spannweite",
    "Download Forces": "Kräfte herunterladen",
    "Choose the type of data that you want":
      "Wählen Sie den gewünschten Datentyp",
    Download: "Herunterladen",
    "Total Force": "Gesamtkraft",
    "Stiffness Force": "Steifigkeitskraft",
    "Damping Force": "Dämpfungskraft",
    "Calculated Value": "Berechneter Wert",
    Threshold: "Schwellenwert",
    "Mean value of contact force": "Mittelwert der Kontaktkraft",
    "Actual maximum of contact force": "Tatsächliches Maximum der Kontaktkraft",
    "Actual minimum of contact force": "Tatsächliches Minimum der Kontaktkraft",
    "Percentage of loss of contact": "Prozentsatz des Kontaktverlustes",
    "Maximum support uplift": "Maximale Stützhebung",
    "STD (incl. low pass filter 20 Hz)": "STD (inkl. Tiefpassfilter 20 Hz)",
    "STD of contact force (0Hz to 20Hz)": "STD der Kontaktkraft (0Hz bis 20Hz)",
    "STD of contact force (5Hz to 20Hz)": "STD der Kontaktkraft (5Hz bis 20Hz)",
    "STD of contact force (0Hz to 5Hz)": "STD der Kontaktkraft (0Hz bis 5Hz)",
    "Max. vertical dis. of contact point":
      "Max. vertikale Verschiebung des Kontaktpunkts",
    "a: analyzed in time-domain": "a: Zeitbereichsanalyse",
    "b: analyzed in frequency-domain": "b: Frequenzbereichsanalyse",
    "STD: standard deviation": "STD: Standardabweichung",
    "Analysis section result": "Analysebereichsergebnis",
    "Vertical pantograph disp.": "Vertikale Pantographenbewegung",
    "Download Video": "Video herunterladen",
    "Pantograph response": "Pantographenreaktion",
    "Catenary response": "Fahrleitungsreaktion",
    "Contact analysis": "Kontaktanalyse",
    "The overlap area is not modeled correctly. Static simulation will proceed, but dynamic simulation might not be possible.":
      "Der Überlappungsbereich ist nicht korrekt modelliert. Die statische Simulation wird fortgesetzt, aber die dynamische Simulation könnte nicht möglich sein.",
    Close: "Schließen",
    "More Info": "Mehr Informationen",
    "Track layout": "Gleislayout",
    "Sending request...": "Anfrage wird gesendet...",
    "Calculation started!": "Berechnung gestartet!",
    Cant: "Überhöhung",
    "Add defect": "Defekt hinzufügen",
    mode: "Modus",
    "Max frequency static": "Maximale statische Frequenz",
    "Max frequency dynamic": "Maximale dynamische Frequenz",
    Tension: "Zugspannung",
    Compression: "Druckspannung",
    "Messenger wire": "Tragseil",
    "Remove joint": "Verbindung entfernen",
    "Add a joint": "Verbindung hinzufügen",
    "The length of span could not be negative or zero":
      "Die Spannlänge darf nicht negativ oder null sein",
    "Please enter a valid number for distance, mass and length.":
      "Bitte geben Sie eine gültige Zahl für Entfernung, Masse und Länge ein.",
    "The clamp cannot be placed on the dropper.":
      "Die Klemme kann nicht am Abnehmer platziert werden.",
    "Invalid value": "Ungültiger Wert",
    "The section insulator cannot be placed on the stitch wire":
      "Der Abschnittsisolator kann nicht auf dem Stützseil platziert werden",
    "cannot be less than 0.": "darf nicht kleiner als 0 sein.",
    "Clamp Detail": "Klemmendetails",
    Model: "Modell",
    "Suspention Type": "Aufhängungstyp",
    "Support elasticity": "Stütz-Elastizität",
    "Support force": "Stützkraft",
    "Messenger cable deflection": "Durchbiegung des Tragseils",
    "Dropper length calculated at commissioning temperature":
      "Abnehmerlänge bei Inbetriebnahmetemperatur berechnet",
    "Pantograph & Catenary Video": "Pantographen- & Fahrleitungsvideo",
    "To setup the report, please recalculate your catenary just once.":
      "Um den Bericht einzurichten, berechnen Sie bitte Ihre Oberleitung nur einmal neu.",
    "Download Report": "Bericht herunterladen",
    "Setup Report": "Bericht einrichten",
    "Special dropper": "Spezial-Abhänger",
    "Tension damping": "Dämpfung bei Zugbelastung",
    "Speed of cut off damping": "Abschaltgeschwindigkeit der Dämpfung",
    "Compression damping": "Dämpfung bei Druckbelastung",
    "Messanger Wire Support": "Botenleitungsstütze",
    "Pantograph model": "Pantografenmodell",
    "Something went wrong, please try again later!":
      "Etwas ist schiefgelaufen, bitte versuchen Sie es später noch einmal!",
    "Your request is being calculated in the background, and corresponding results can be seen as soon as it comes.":
      "Ihre Anfrage wird im Hintergrund berechnet, und die entsprechenden Ergebnisse können angezeigt werden, sobald sie verfügbar sind.",
    "Length (m)": "Länge (m)",
    "Masse (kg)": "Masse (kg)",
    "Length between suspension (m)": "Länge zwischen Aufhängung (m)",
    Regid: "Steif",
    Bilinear: "Bilinear",
    "Buckling theory": "Knicktheorie",
    "Mass (Kg)": "Masse (kg)",
    "Use Defaults": "Standardwerte verwenden",
    Modify: "Ändern",
    "The mechanical properties are not modified.":
      "Die mechanischen Eigenschaften wurden nicht geändert.",
    "Do you want to use the default values?":
      "Möchten Sie die Standardwerte verwenden?",
    "Modifying mechanical properties": "Mechanische Eigenschaften ändern",
    "Geometric data changed!": "Geometrische Daten geändert!",
    "Please check zigzag, messenger cable and contact wire values.":
      "Bitte überprüfen Sie die Werte für Zickzack, Tragseil und Fahrdraht.",
    "Default model": "Standardmodus",
    "Calculation Time:": "Berechnungszeit:",
    "registration arm": "Registrierarm",
    "Add a joint dropper": "Verbindungstropfer hinzufügen",
    "Remove joint dropper": "Verbindungstropfer entfernen",
    "compare with": "vergleichen mit",
    CompareWith: "Vergleichen mit",
    "Add Section": "Abschnitt hinzufügen",
    "Enter a valid name for catenary":
      "Geben Sie einen gültigen Namen für die Oberleitung ein",
    "Please enter a model": "Bitte geben Sie ein Modell ein",
    "Uniform tension on messenger cable":
      "Gleichmäßige Spannung am Leitungskabel",
    "Non uniform tension on messenger cable":
      "Ungleichmäßige Spannung am Leitungskabel",
    "Simulation Name": "Simulationsname",
    "Users Access": "Benutzerzugriff",
    "Select user": "Benutzer auswählen",
    "Can view": "Kann anzeigen",
    "Can edit": "Kann bearbeiten",
    Name: "Name",
    Accessibility: "Zugänglichkeit",
    "Last editing": "Letzte Bearbeitung",
    you: "Du",
    Owner: "Eigentümer",
    "Simulation name is required": "Simulationsname ist erforderlich",
    "Simulation updated successfully": "Simulation erfolgreich aktualisiert",
    "Simulation Setting": "Simulations-Einstellungen",
    Update: "Aktualisieren",
    "Estimating required time for the dynamic simulation. Please wait...":
      "Schätzung der benötigten Zeit für die dynamische Simulation. Bitte warten...",
    "By sending a new dynamic request":
      "Durch das Senden einer neuen dynamischen Anfrage",
    "the previous static result data will be deleted!":
      "werden die vorherigen statischen Ergebnisdaten gelöscht!",
    "Cancel Request": "Anfrage abbrechen",
    "You will be redirected to the results page shortly.":
      "Sie werden in Kürze zur Ergebnis-Seite weitergeleitet.",
    "Dynamic calculation warning": "Warnung zur dynamischen Berechnung",
    "Calculating dynamic data...": "Berechnung der dynamischen Daten...",
    "Estimated Value:": "Geschätzter Wert:",
    "Note: The estimated value is for the segment":
      "Hinweis: Der geschätzte Wert gilt für das Segment",
    "of the contact wire with consistent height.":
      "des Kontaktleiters mit konstantem Höhenverlauf.",
    "Please re-calculate the static modeling.":
      "Bitte berechnen Sie das statische Modell erneut.",
    "Please fill in all fields.": "Bitte füllen Sie alle Felder aus.",
    "Your request has been successfully sent":
      "Ihre Anfrage wurde erfolgreich gesendet",
    Send: "Senden",
    "Request custom catenary": "Benutzerdefinierte Oberleitung anfordern",
    "Invalid data": "Ungültige Daten",
    "Sorry, the simulation report creation was unsuccessful":
      "Entschuldigung, die Erstellung des Simulationsberichts war nicht erfolgreich",
    Setup: "Einrichtung",
    "Could not download file!":
      "Die Datei konnte nicht heruntergeladen werden!",
    "Your simulation report is ready": "Ihr Simulationsbericht ist fertig",
    "Download the Report": "Bericht herunterladen",
    "Please wait a few minutes": "Bitte warten Sie ein paar Minuten",
    "Sort by": "Sortieren nach",
    "Saving changes...": "Änderungen werden gespeichert...",
    "Changes saved successfully!": "Änderungen erfolgreich gespeichert!",
    "Failed to save changes. Please try again!":
      "Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut!",
    "Linear stiffness (N/m)": "Lineare Steifigkeit (N/m)",
    "Compression Damping (N.s/m)": "Dämpfung bei Druckbelastung (N.s/m)",
    "Tension Damping (N.s/m)": "Dämpfung bei Zugbelastung (N.s/m)",
    "Speed of cut off Damping (m/s)":
      "Abschaltgeschwindigkeit der Dämpfung (m/s)",
    "Selecting this option": "Durch Auswahl dieser Option",
    "Generates a simulation": "Erstellt eine Simulation",
    "Video, which will increase": "Video, das erhöhen wird",
    "Video, calculation time considerably":
      "Video, wodurch sich die Berechnungszeit erheblich erhöht",
    "Structural damping in the wire (1/s)":
      "Strukturelle Dämpfung im Draht (1/s)",
    More: "Mehr",
    Less: "Weniger",
    options: "Optionen",
    STATIC: "STATTISCH",
    DYNAMIC: "DYNAMISCH",
    VIDEO: "VIDEO",
    "Solved Dynamically": "Dynamisch Gelöst",
    "Solved Statically": "Statisch Gelöst",
    "Solved With Video": "Mit Video gelöst",
    Solve: "Lösen",
    "Solve Status": "Lösungsstatus",
  },
};
