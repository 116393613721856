export default {
  server_log: {
    "1Hour ago": "1 ora fa",
    "Logging date": "Data di registrazione",
    Time: "Tempo",
    "Device name": "Nome del dispositivo",
    Acc: "Acc",
    System: "Sistema",
    Battery: "Batteria",
    GPS: "GPS",
    laser: "Laser",
    Error: "Errore",
    Abnormality: "Anomalia",
    Record: "Registrazione",
    Receive: "Ricevuto",
    Device: "Dispositivo",
    Temp: "Temperatura",
    Gps: "GPS",
    Laser: "Laser",
    LaserV: "LaserV",
    Tower: "Torre",
  },
};
