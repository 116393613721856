export default {
  new_events: {
    New: "Nuovo",
    abnormalities: "Anomalie",
    Total: "Totale",
    Type: "Tipo",
    "Point Detail": "Dettaglio punto",
    "Downloading...": "Scaricamento...",
    "Download Signal": "Scaricare",
    "Point status": "Stato del punto",
    "Video Requested": "Video richiesto",
    Requesting: "Richiesta in corso",
    "Request Video": "Richiedi video",
    "Not Requested": "Non richiesto",
    Requested: "Richiesto",
    "Request Seen": "Richiesta registrata",
    "Not Recorded": "Non registrato",
    Received: "Ricevuto",
    "No Abnormalities found": "Nessuna anomalia trovata",
    "See on map": "Vedi sulla mappa",
    "Sorry, this signal doesn't have data":
      "Spiacente, questo segnale non contiene dati.",
  },
};
