export default {
  views: {
    Cancel: "Annulla",
    OK: "OK",
    "With gps": "Con gps",
    "With speed": "Con velocità",
    "With measured data": "Con dati misurati",
    "Device is ON": "Dispositivo acceso",
    "No trip found": "Nessun viaggio trovato",
    "Device name": "Nome dispositivo",
    "No. of Trips": "Numero di viaggi",
    "Choose duration from calendar": "Scegli la durata dal calendario",
    "Copied URL": "URL copiato",
    "Copy URL": "Copia URL",
    "For continue, you should login again":
      "Per continuare, dovresti effettuare nuovamente il login",
    "Email address": "Indirizzo email",
    Password: "Password",
    Login: "Accedi",
    Register: "Registrati",
    "Something went wrong": "Qualcosa è andato storto",
    "The period of time not selected please select the time":
      "Il periodo di tempo non è selezionato. Per favore, seleziona il tempo",
    "Share This Page": "Condividi questa pagina",
  },
};
