export default {
  device_setting: {
    "Validation Error": "Validierungsfehler",
    "Please enter a train name": "Bitte geben Sie einen Zugnamen ein",
    "Please enter device name": "Bitte geben Sie den Gerätenamen ein",
    "Please choose a train": "Bitte wählen Sie einen Zug aus",
    "Please enter api address": "Bitte geben Sie die API-Adresse ein",
    "Device created": "Gerät erstellt",
    "Device successfully created": "Gerät erfolgreich erstellt",
    "Device updated": "Gerät aktualisiert",
    "Device successfully updated": "Gerät erfolgreich aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Try again later": "Versuchen Sie es später erneut",
    Add: "Hinzufügen",
    Edit: "Bearbeiten",
    "Add Device": "Gerät hinzufügen",
    UserName: "Nutzername",
    "Edit Device": "Gerät bearbeiten",
    "Device Position on train": "Geräteposition im Zug",
    Position: "Position",
    "API address": "API-Adresse",
    Gps: "GPS",
    Camera: "Kamera",
    Temperature: "Temperatur",
    Battery: "Batterie",
    Acceleration: "Beschleunigung",
    Laser: "Laser",
    Train: "Zug",
    "Add new train": "Neuen Zug hinzufügen",
    "The device access could not be removed.":
      "Der Gerätezugriff konnte nicht entfernt werden.",
    "Device access removed": "Gerätezugriff entfernt",
    "The device access removed successfully.":
      "Der Gerätezugriff wurde erfolgreich entfernt.",
    "No user defined for this device":
      "Kein Benutzer für dieses Gerät definiert",
    Name: "Name",
    Role: "Rolle",
    "The device access could not be added.":
      "Der Gerätezugriff konnte nicht hinzugefügt werden.",
    "Device access added": "Gerätezugriff hinzugefügt",
    "The device access added successfully.":
      "Der Gerätezugriff wurde erfolgreich hinzugefügt.",
    "Device Access": "Gerätezugriff",
    "Choose user": "Benutzer auswählen",
    "Make report": "Bericht schreiben",
    "Acceleration data": "Beschleunigungsdaten",
    "System metric": "Systemmetrik",
    "GPS data": "GPS-Daten",
    Speed: "Geschwindigkeit",
    Video: "Video",
    "Margin from above": "Rand von oben",
    "Max jump between frames": "Maximaler Sprung zwischen Frames",
    "Wiper period fast": "Wischperiode schnell",
    "Wiper period slow": "Wischerperiode langsam",
    "Wiper period": "Wischerperiode",
    "Please try again later.": "Bitte versuchen Sie es später erneut.",
    "Device environment updated": "Geräteumgebung aktualisiert",
    "The environment data will be set": "Die Umgebungsdaten werden eingestellt",
    "Device environment data": "Geräteumgebungsdaten",
    "Real or test data": "Real- oder Testdaten",
    "real data": "echte daten",
    "test mode": "testmodus",
    "Video processing": "Videobearbeitung",
    Wiper: "Wischer",
    "Set on device": "Auf Gerät einstellen",
    "Installation Date": "Installationsdatum",
    "Device Last Status": "Letzter Status des Geräts",
    "Last boot": "Letzter Start",
    "Last connect": "Letzte Verbindung",
    "Bytes sent": "Bytes gesendet",
    "Bytes recv": "Bytes empfangen",
    "CPU temperature": "CPU-Temperatur",
    "CPU percent": "CPU-Prozent",
    "Memory percent used": "Speicher %",
    "Disk percent used": "Festplatten %",
    "Main Acc": "Haupt gem",
    "Solar Panel": "Sonnenkollektor",
    Ultrasonic: "Ultraschall",
    "Wind turbine": "Windkraftanlage",
    "Train roof": "Zugdach",
    Outside: "Außen",
    PANTObank: "PANTObank",
    PANTOcase: "PANTOcase",
    Temp: "Temp",
    Acc: "Acc",
    "Device ID": "Geräte ID",
    "Not set": "Nicht eingestellt",
    Copied: "Kopiert",
    Copy: "Kopieren",
    Loading: "Wird geladen",
    "Installation Picture": "Installationsbild",
    Deleting: "Löschen",
    Uploading: "Hochladen",
    "Calibration Video": "Kalibrierungsvideo",
    "We support": "Wir unterstützen",
    "And size must be less than": "Und die Größe muss kleiner sein als",
    "Train speed": "Zuggeschwindigkeit",
    "Sensors Calibration": "Kalibrierung der Sensoren",
    "Sensors calibration updated": "Sensorkalibrierung aktualisiert",
    "The calibration data will be set": "Die Kalibrierdaten werden eingestellt",
    "Enable blur": "Unschärfe aktivieren",
    "Set Video Blur": "Video-Unschärfe einstellen",
    Width: "Breite",
    Height: "Höhe",
    Save: "Speichern",
    Cancel: "Abbrechen",
    "Device Color:": "Geräte farbe:",
    "An error occurred while creating the device":
      "Beim Erstellen des Geräts ist ein Fehler aufgetreten",
    "Device Enable": "Gerät aktivieren",
    "Device Name": "Gerätename",
    "Laser Calibration": "Laserkalibrierung",
    "edit device": "Gerät bearbeiten",
    "Overall data video volume": "Datenvolumen",
    "Operation time of device": "Betriebszeit des Geräts",
    "Device Last Statuse": "Letzter Status des Geräts",
  },
};
