export default {
  trigger: {
    "Trigger created": "Trigger creato",
    "Trigger created successfully": "Trigger creato con successo",
    "Creating trigger failed": "Creazione del trigger fallita",
    "Condition one is required": "La condizione uno è obbligatoria",
    "Validation Error": "Errore di validazione",
    "Condition two is required When you choose and/or":
      "La condizione due è obbligatoria quando scegli e/o",
    "Please select a device": "Seleziona un dispositivo",
    "Please select an action": "Seleziona un'azione",
    "Please write an email": "Scrivi un'email",
    "The conditions should not be equal to each other!":
      "Le condizioni non devono essere uguali tra loro!",
    "All Points": "Tutti i punti",
    "At risk": "A rischio",
    Unhealthy: "Non salutare",
    "Need to attention": "Necessita attenzione",
    If: "Se",
    Select: "Seleziona",
    "Email address": "Indirizzo email",
    "Add a trigger": "Aggiungi un trigger",
    "Make a sensor trigger": "Crea un trigger sensore",
    In: "In",
    Then: "Poi",
    Email: "Email",
    "Created by": "Creato da",
    "Delete the trigger": "Elimina il trigger",
    "Condition 1": "Condizione 1",
    "Condition 2": "Condizione 2",
    Action: "Azione",
    "Date Time": "Data Ora",
    "Trigger deleted": "Trigger eliminato",
    "Trigger deleted successfully": "Trigger eliminato con successo",
    "Creating trigger failed!": "Creazione del trigger fallita!",
    "Something went wrong!": "Qualcosa è andato storto!",
    "Are you sure you want to delete this trigger?":
      "Sei sicuro di voler eliminare questo trigger?",
    "You can create a new trigger": "Puoi creare un nuovo trigger",
    "using the top section": "utilizzando la sezione superiore",
    Id: "ID",
    Connector: "Connettore",
    Device: "Dispositivo",
    DateTime: "Data Ora",
    Delete: "Elimina",
  },
};
