import { types } from "../types";
import { getTimeAgo } from "helpers/getDateTime";

const initialState = {
  eventsLoading: false,
  pointsLoading: false,

  isCameBackFromPointDetail: false,

  isReload: true,
  isImportedData: true,

  lastUrlHistory: null,

  isOpenFilter: false,
  pointFilter: [
    { value: "at_risk", enable: false },
    { value: "unhealthy", enable: false },
    { value: "go_check", enable: false },
    { value: "checked", enable: false },
    { value: "favorite", enable: false },
  ],
  checkingStatusFilter: [
    { value: "false_alarm", enable: false },
    { value: "need_repair", enable: false },
    { value: "noneed_repair", enable: false },
    { value: "repaired", enable: false },
  ],
  ignoredStatusFilter: [
    { value: "joint", enable: false },
    { value: "overlap", enable: false },
    { value: "cantilever", enable: false },
    { value: "other", enable: false },
  ],
  events: [],
  selectedEvents: [],

  points: [],
  selectedPoints: [],

  totalEventNumber: null,
  totalPointNumber: null,

  statusFilterList: [],
  lastStatuseCounts: {},
  selectedTab: "points",
  isSelectedAllPoints: false,
  eventsTypes: [
    "acc",
    "height",
    "zigzag",
    "force",
    "cross_distance",
    "cableRemain",
    "arc",
    "f2",
  ],

  highlightedEvent: "",
  highlightedPoint: "",

  offsetEvents: null,
  offsetPoints: null,
  scrollToEvent: null,
  scrollToPoint: null,

  selectedEventsFilter: null,
  selectedPointsFilter: null,

  eventsFilterCounts: 500,
  eventsFilterRange: [getTimeAgo("week", 1), new Date().getTime()],

  pointsFilterDate: "all",
  pointsFilterRange: [getTimeAgo("week", 1), new Date()],

  pointsSortBy: "possibility",
  eventsSortBy: "time",
  toolsOpen: null, // eventId or pointId

  pointsSortByAbnormalityType: [],
  pointsSortByStatus: [],
  pointsSortByType: [],
  pointsFilterBy: [],
  pointsSortByHealth: [],

  selectedSection: null,

  mapOptions: {
    mapHeightActive: true,
    mapZigzagActive: true,
    mapPointsActive: true,
    mapForceActive: true,
    mapCrossDistanceActive: true,
    mapCableRemain: true,
    mapArcActive: true,
    mapF2Active: true,
    mapCityNameActive: true,
    mapSectionsActive: false,
    mapRailwaysActive: false,
  },
};

export default function catenaryPointsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.UPDATE_SELECTED_TAB:
      return { ...state, selectedTab: payload };

    case types.UPDATE_SELECTED_ALL_POINTS:
      return { ...state, isSelectedAllPoints: payload };

    case types.UPDATE_CAME_BACK_FROM_POINT_DETAIL:
      return { ...state, isCameBackFromPointDetail: payload };

    case types.GET_EVENTS_REQUEST:
      return { ...state, eventsLoading: true };
    case types.GET_EVENTS_SUCCESS:
      return { ...state, eventsLoading: false, ...payload };
    case types.GET_EVENTS_FAILURE:
      return { ...state, eventsLoading: false };

    case types.CATENARY_POINTS_SET_OFFSET_EVENTS:
      return { ...state, offsetEvents: payload };
    case types.CATENARY_POINTS_SET_OFFSET_POINTS:
      return { ...state, offsetPoints: payload };
    case types.CATENARY_POINTS_SCROLL_TO_EVENT:
      return { ...state, scrollToEvent: payload };
    case types.CATENARY_POINTS_SCROLL_TO_POINT:
      return { ...state, scrollToPoint: payload };
    case types.LAST_URL_HISTORY:
      return { ...state, lastUrlHistory: payload };

    case types.GET_POINTS_REQUEST:
      return { ...state, pointsLoading: true };
    case types.GET_POINTS_SUCCESS:
      return { ...state, pointsLoading: false, ...payload };
    case types.GET_POINTS_FAILURE:
      return { ...state, pointsLoading: false };

    case types.UPDATE_HIGHLIGHTED_EVENT:
      return { ...state, highlightedEvent: payload };
    case types.UPDATE_HIGHLIGHTED_POINT:
      return { ...state, highlightedPoint: payload };

    case types.UPDATE_SELECTED_EVENTS_FILTER:
      return { ...state, selectedEventsFilter: payload };
    case types.UPDATE_SELECTED_POINTS_FILTER:
      return { ...state, selectedPointsFilter: payload };

    case types.UPDATE_EVENTS_FILTER_COUNTS:
      return { ...state, eventsFilterCounts: payload };
    case types.UPDATE_EVENTS_FILTER_RANGE:
      return { ...state, eventsFilterRange: payload };

    case types.UPDATE_POINTS_FILTER_DATE:
      return { ...state, pointsFilterDate: payload };

    case types.UPDATE_STATUS_FILTER_LIST:
      return {
        ...state,
        statusFilterList: payload,
      };

    case types.UPDATE_POINTS_FILTER_RANGE:
      return { ...state, pointsFilterRange: payload };

    case types.UPDATE_TOOLS_OPEN:
      return { ...state, toolsOpen: payload };
    case types.UPDATE_POINTS_SORT_BY:
      return { ...state, pointsSortBy: payload };
    case types.UPDATE_EVENTS_SORT_BY:
      return { ...state, eventsSortBy: payload };

    case types.UPDATE_POINTS_SORT_BY_STATUS:
      return { ...state, pointsSortByStatus: payload };
    case types.UPDATE_POINTS_SORT_BY_Health:
      return { ...state, pointsSortByHealth: payload };
    case types.UPDATE_POINTS_SORT_BY_ABNORMALITY_TYPE:
      return { ...state, pointsSortByAbnormalityType: payload };
    case types.UPDATE_POINTS_FILTER_BY:
      return { ...state, pointsFilterBy: payload };
    case types.UPDATE_POINTS_SORT_BY_TYPE:
      return { ...state, pointsSortByType: payload };

    case types.UPDATE_IS_OPEN_FILTER:
      return { ...state, isOpenFilter: payload };

    case types.SET_SELECTED_SECTION:
      return { ...state, selectedSection: payload };

    case types.UPDATE_POINT_FILTER:
      return {
        ...state,
        pointFilter: state.pointFilter.map(item => {
          if (item.value !== payload.value) return item;
          return { ...item, enable: payload.enable };
        }),
      };

    case types.UPDATE_CHECK_STATUS_FILTER:
      return {
        ...state,
        checkingStatusFilter: state.checkingStatusFilter.map(item => {
          if (item.value !== payload.value) return item;
          return { ...item, enable: payload.enable };
        }),
      };

    case types.UPDATE_IGNORED_STATUS_FILTER:
      return {
        ...state,
        ignoredStatusFilter: state.ignoredStatusFilter.map(item => {
          if (item.value !== payload.value) return item;
          return { ...item, enable: payload.enable };
        }),
      };

    case types.UPDATE_SELECTED_EVENTS:
      return { ...state, selectedEvents: payload };
    case types.UPDATE_SELECTED_POINTS:
      return { ...state, selectedPoints: payload };

    case types.UPDATE_MAP_OPTIONS:
      return {
        ...state,
        mapOptions: { ...state.mapOptions, [payload.key]: payload.value },
      };
    case types.UPDATE_EVENTS_TYPE:
      return { ...state, eventsTypes: payload };

    case types.UPDATE_IS_IMPORTED_DATA:
      return { ...state, isImportedData: payload };
    case types.UPDATE_IS_RELOAD_QUERY_CATENARY_POINT:
      return { ...state, isReload: payload };

    default:
      return state;
  }
}
