export default {
  panel_layout: {
    "403 — ACCESS DENIED": "403 — ACCESSO NEGATO",
    "You currently does not have access to this page.":
      "Attualmente non hai accesso a questa pagina.",
    "Please try again later.": "Per favore riprova più tardi.",
    "It seems that your connection to the Internet has been interrupted. Please check your internet!":
      "Sembra che la tua connessione a Internet sia stata interrotta. Controlla la tua connessione!",
    "Fetching...": "Caricamento...",
    EmptyNotifications: "Non hai notifiche",
    "Panel Mode": "Modalità Pannello",
    Language: "Lingua",
    Theme: "Tema",
    "Internal Server Error": "Errore Interno del Server",
    "Bad Gateway": "Bad Gateway",
    "Sorry! It is not possible to access the server at the moment.":
      "Spiacente! Al momento non è possibile accedere al server.",
    "Sorry! There is a problem on the server side that our team is working on. Thank you for your patience.":
      "Spiacente! C'è un problema dal lato del server su cui il nostro team sta lavorando. Grazie per la tua pazienza.",
    "Contact the": "Contatta il",
    "team support": "supporto del team",
    Time: "Tempo",
    "Error Code": "Codice Errore",
    Domain: "Dominio",
    Company: "Azienda",
  },
};
