export default {
  pantographs: {
    "Choose a specific trip & time": "Wählen Sie eine bestimmte Fahrt und Zeit",
    Last: "Zuletzt",
    abnormalities: "Anomalien",
    Sections: "Abschnitte",
    "City Name": "Stadtname",
    Railways: "Eisenbahnen",
    "Make a report": "Berichtserstellung",
    "Sort by": "Sortieren nach",
    Time: "Zeit",
    Status: "Status",
    Speed: "Geschwindigkeit",
    "No. of abnormalities": "Anzahl der Anomalien",
    "Type/Total": "Typ/Gesamt",
    "abnormality/Total": "Anomalie/Gesamt",
    "Shock Point": "Stosspunkt",
    Favorite: "Favorit",
    Total: "Gesamt",
    Type: "Typ",
    found: "gefunden",
    No: "Nein",
    New: "Neu",
    "Point status": "Punktstatus",
    "Go to check": "Zur Überprüfung gehen",
    Created: "Erstellte ",
    Ignore: "Ignorieren",
    "False alarm": "Fehlalarm",
    Repaired: "Bearbeitet",
    "Need repair": "Aktionr erforderlich",
    "NoNeed repair": "keine  AKtion erforderlich",
    "Point Detail": "Punktdetail",
    "Download Signal": "herunterladen",
    "Downloading...": "Wird heruntergeladen...",
    "Not Requested": "Nicht angefordert",
    Requested: "Angefordert",
    "Request Seen": "Anfrage registriert",
    "Not Recorded": "Keine Aufnahme",
    Received: "Empfangen",
    "Video Requested": "Video angefordert",
    Requesting: "Anfordern",
    "Request Video": "Video anfordern",
    "Checked points": "Geprüfte Kontrollpunkt",
    "Carbon strip": "Schleifleisten",
    pantographs: "Stromabnehmer",
    Pantovision: "Pantovision",
    "Sorry, this signal doesn't have data":
      "Tut mir leid, dieses Signal enthält keine Daten",
    "Requested Time": "Angeforderte Zeit",
    "Created Condition": "Bedingung erstellt",
    "Estimated Remaining Time": "Geschätzte verbleibende Zeit",
    "Video Time": "Videozeit",
    "Condition Created": "Bedingung erstellt",
    "Dynamic Model Calculation": "Berechnung des dynamischen Modells",
    "Second Time Label": "Zweite Zeitbezeichnung",
    By: "Von",
    Model: "Modell",
    "Simulation Report": "Simulationsbericht",
    "Setup Report": "Einrichtungsbericht",
    "Trigger Condition": "Auslösebedingung",
  },
};
