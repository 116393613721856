export default {
  catenary_point: {
    "At risk": "In Gefahr",
    "Catenary point filter": "Punktfilter",
    "Checked points": "Geprüfte Kontrollpunkt",
    "Choose a specific trip & time": "Wählen Sie eine bestimmte Fahrt und Zeit",
    "City Name": "Stadtname",
    "Download Signal": "herunterladen",
    "Downloading...": "herunterladen...",
    "Dropper drop": "Hängerabsenkung",
    "Dropper rupture": "Hänger-Bruch",
    "Abnormality/Total": "Anomalie/Gesamt",
    "False alarm": "Fehlalarm",
    "Fault category": "Fehlerkategorie",
    "Go to check": "Zur Überprüfung gehen",
    "Ignored points": "Ignorierte Kontrollpunkte",
    "Make a report": "Berichtserstellung",
    "Need repair": "Aktionr erforderlich",
    "Need to repair": "Aktionr erforderlich",
    "Need to check on": "Bedarf einer Sie überprüfung",
    "No.of abnormality": "Anzahl der Anomalien",
    "NoNeed repair": "keine  AKtion erforderlich",
    "No Need to repair": "keine erforderlich",
    "Not Recorded": "Keine Aufnahme",
    "Not Requested": "Nicht gefordert",
    "Overhead drop": "Overhead-Drop",
    "Point Detail": "Kontrollpunktdetail",
    "Point status": "Punktstatus",
    "Request Seen": "Anfrage registriert",
    "Request Video": "Video anfordern",
    "Scratched C.W": "Zerkratztes C.W",
    "Shock Point": "Stosspunkt",
    "Sorry, this signal doesn't have data":
      "Tut mir leid, dieses Signal enthält keine Daten",
    "Sort by": "Sortieren nach",
    "Type/Total": "Typ/Gesamt",
    "Video Requested": "Video anfordert",
    "Zig-Zag": "Zickzack",
    Cantilever: "Ausleger",
    Checked: "Geprüft",
    Corrosion: "Korrosion",
    Counts: "Zählt",
    Created: "Erstellte ",
    abnormalities: "Anomalien",
    Favorite: "Favorit",
    found: "gefunden",
    Height: "Höhe",
    Ignore: "Ignorieren",
    Joint: "Zusammen",
    Last: "Zuletzt",
    New: "Neu",
    No: "Nein",
    Other: "Andere",
    Overlap: "Überlappung",
    points: "Punkte",
    Points: "Punkte",
    Railways: "Eisenbahnen",
    Received: "Empfangen",
    Repaired: "Bearbeitet",
    Requested: "gefordert",
    Requesting: "Anfordern",
    Sections: "Abschnitte",
    Speed: "Geschwindigkeit",
    Status: "Status",
    Time: "Zeit",
    Total: "Gesamt",
    Type: "Typ",
    Unhealthy: "Schadhaft",
    Wearing: "Verschleiß",
    "imported data": "importierte Daten",
    "please check mark a row(s) to make report":
      "Bitte markieren Sie eine oder mehrere Zeilen, um einen Bericht zu erstellen.",
    "Load All": "Alle laden",
    "Are you sure you want to delete this abnormality?":
      "Sind Sie sicher, dass Sie diese Anomalie löschen möchten?",
    "Delete Abnormality": "Anomalie löschen",
    Delete: "Löschen",
    Cancel: "Stornieren",
    "The abnormality could not be removed.":
      "Die Anomalie konnte nicht entfernt werden.",
    "something went wrong.": "etwas ist schief gelaufen",
    "Imported abnormality was removed successfully":
      "Die importierte Anomalie wurde erfolgreich entfernt",
    "Abnormality removed": "Anomalie entfernt",
    "Archived Points": "Archivierte Punkte",
    Favorites: "Favoriten",
    "Make Report": "Bericht erstellen",
    time: "Zeit",
    speed: "Geschwindigkeit",
    "Possibility of Problem": "Möglichkeit eines Problems",
    "Created Time": "Erstellungszeit",
    "Updated Time": "Aktualisierte Zeit",
    Impact: "Auswirkung",
    Thickness: "Dicke",
    Force: "Kraft",
    "zig zag": "Zickzack",
    Arc: "Bogen",
    "Abnormality type": "Abweichungstyp",
    Nothing: "Nichts",
    "Section Insulator": "Streckentrenner",
    "Mast (tower)": "Mast (Turm)",
    Crossing: "Kreuzung",
    Clamp: "Klemme",
    Curve: "Kurve",
    "Turn out": "Weiche",
    Suspicious: "Verdächtig",
    "Health status": "Gesundheitsstatus",
    "No Need to Repair": "Keine Reparatur erforderlich",
    "Go Check": "Zur Überprüfung gehen",
    "Need to Repair": "Reparatur erforderlich",
    "No Status": "Kein Status",
    "Maintenance status": "Wartungsstatus",
    "No Data": "Keine Daten",
    "fault category": "Fehlerkategorie",
    "point status": "Punktstatus",
    "Upload pic": "Bild hochladen",
    "The image could not be imported.":
      "Das Bild konnte nicht importiert werden.",
    created: "Noch keine Aktion!",
    "Checking...": "Überprüfung läuft...",
    "No action yet!": "Noch keine Aktion!",
    "Check Date": "Datum prüfen",
    "checked by": "Geprüft von",
    "Search location...": "Standort suchen...",
    success: "Erfolg",
    "Point is": "Punkt ist",
    Point: "Punkt",
    "Point '{{streetName}}' is Unarchived and will appear in the Catenary points list.":
      "Punkt '{{streetName}}' ist nicht archiviert und wird in der Kettenpunkte-Liste erscheinen.",
    "Point '{{streetName}}' is Archived and won't appear in the Catenary points list. You can find it using the sort feature.":
      "Punkt '{{streetName}}' ist archiviert und wird nicht in der Kettenpunkte-Liste erscheinen. Sie können ihn mit der Sortierfunktion finden.",
    "Point is {{action}}": "Punkt ist {{action}}",
    Archived: "Archiviert",
    Unarchived: "Nicht archiviert",
    archived: "archiviert",
    unarchived: "nicht archiviert",
    error: "Fehler",
    cannot_archive_or_unarchive:
      "Du kannst Punkt '{{action}}' nicht archivieren!",
    "You can't": "Du kannst nicht",
    "this point!": "diesen Punkt!",
    "Unable to update favorite status. Try again later.":
      "Der Favoritenstatus konnte nicht aktualisiert werden. Bitte versuche es später erneut.",
    "There is no point to add a status":
      "Es gibt keinen Punkt, dem ein Status hinzugefügt werden kann.",
    "Something Went Wrong! Please Try Again Later":
      "Etwas ist schiefgelaufen! Bitte versuche es später erneut.",
    "Point status added": "Punktstatus hinzugefügt",
    "Point status has been added successfully":
      "Punktstatus wurde erfolgreich hinzugefügt",
    "Added to Favorites": "zu den Favoriten hinzugefügt",
    "Removed from Favorites": "aus den Favoriten entfernt",
    "Point is {{successTitle}}": "Punkt ist {{successTitle}}",
    "Point '{{streetName}}' is added to your Favorites and you can find it using the sort feature.":
      "Punkt '{{streetName}}' wurde zu deinen Favoriten hinzugefügt und kann mit der Sortierfunktion gefunden werden.",
    "Point '{{streetName}}' removed from your Favorites and will no longer appear in the Favorites list.":
      "Punkt '{{streetName}}' wurde aus deinen Favoriten entfernt und erscheint nicht mehr in der Favoritenliste.",
    "View detail": "Details anzeigen",
    "Go check": "Überprüfen Sie es",
    Archive: "Archivieren",
    Unarchive: "Archiv entfernen",
    "Copied:": "Kopiert:",
    Copy: "Kopieren",
    "The number should be less than 1000":
      "Die Zahl sollte kleiner als 1000 sein",
    "1Y": "1J",
    All: "Alle",
    "Validation Error": "Validierungsfehler",
    "Please select or filter points before generating a report!":
      "Bitte wählen oder filtern Sie Punkte, bevor Sie einen Bericht erstellen!",
    "Filtering for the last six weeks": "Filtern der letzten sechs Wochen",
    "Filtering for the last six months": "Filtern der letzten sechs Monate",
    "Filtering for the last six years": "Filtern der letzten sechs Jahre",
    "Point status:": "Punktstatus:",
    Processing: "Verarbeitung",
    Corrupted: "Beschädigt",
    "Video Deleted": "Video gelöscht",
    "Sorted by Time": "Nach Zeit sortiert",
    "Loading more...": "Mehr laden...",
    "Please select a maintenance status.":
      "Bitte wählen Sie einen Wartungsstatus aus.",
    "Something went wrong": "Etwas ist schiefgelaufen",
    "Image upload failed. Please try again.":
      "Bild-Upload fehlgeschlagen. Bitte versuchen Sie es erneut.",
    "Image has been uploaded successfully":
      "Bild wurde erfolgreich hochgeladen",
    "Image is uploaded": "Bild wurde hochgeladen",
    "The maximum allowed size for uploaded images is 5mb!":
      "Die maximal zulässige Größe für hochgeladene Bilder beträgt 5 MB!",
    "Image is deleted": "Bild wurde gelöscht",
    "Image has been deleted successfully": "Bild wurde erfolgreich gelöscht",
    Save: "Speichern",
    "Make a Checked Status": "Erstellen Sie einen geprüften Status",
    "No need to repair": "Keine Reparatur erforderlich",
    "Description:": "Beschreibung:",
    "Choose Status:": "Status auswählen:",
    "something went wrong": "etwas ist schiefgelaufen",
    "Counts:": "Anzahl:",
    Map: "Karte",
    "You don't assign to any device": "Sie haben keinem Gerät zugewiesen",
    "The abnormality could not be added.":
      "Die Abnormalität konnte nicht hinzugefügt werden.",
    "Abnormality added": "Abnormalität hinzugefügt",
    "The abnormality added successfully.":
      "Die Abnormalität wurde erfolgreich hinzugefügt.",
    "If you sort abnormality by time you can find easily this abnormality.":
      "Wenn Sie die Abnormalität nach Zeit sortieren, können Sie sie leicht finden.",
    "The point could not be updated.":
      "Der Punkt konnte nicht aktualisiert werden.",
    "Point updated": "Punkt aktualisiert",
    "The point updated successfully.":
      "Der Punkt wurde erfolgreich aktualisiert.",
    "Make abnormality": "Abnormalität erstellen",
    "Update point": "Punkt aktualisieren",
    "Wire wearing": "Leitungsabnutzung",
    Tension: "Spannung",
    "Wire height overrun": "Leitungshöhenüberschreitung",
    "Wire zig-zag overrun": "Leitungs-Zickzack-Überschreitung",
    Auflauf: "Auflauf",
    Description: "Beschreibung",
    "please find your location": "Bitte finden Sie Ihren Standort",
    "please select a point": "Bitte wählen Sie einen Punkt aus",
    Copied: "Kopiert",
    "Sorry, we don't have any signal": "Entschuldigung, wir haben kein Signal",
    "Not recorded": "Nicht aufgezeichnet",
    "Seen by device": "Vom Gerät gesehen",
    "Sent to device": "An Gerät gesendet",
    Filter: "Filtern",
    "No data": "Keine Daten",
    "Select all for make report": "Alle auswählen für den Bericht erstellen",
    "Report name": "Berichtsname",
    "Junction (crossroad)": "Kreuzung",
    Set: "setzen",
    "Report name cannot exceed 150 characters":
      "Der Berichtname darf 150 Zeichen nicht überschreiten",
    "Description cannot exceed 574 characters":
      "Die Beschreibung darf 574 Zeichen nicht überschreiten",
    "Download PDF": "PDF herunterladen",
    Language: "Sprache",
  },
};
