import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import CalendarTrip from "app/pages/CalendarTrips/language.de";
import CalendarTripsWidget from "app/pages/Home/Widgets/CalendarTrips/language.de";
import CatenaryPoints from "app/pages/CatenaryPoints/language.de";
import CatenaryPointSummaryWidget from "app/pages/Home/Widgets/PointsSummery/language.de";
import DeviceOnTrainWidget from "app/pages/Home/Widgets/DeviceOnTrains/language.de";
import DeviceSetting from "app/pages/DeviceSettings/language.de";
import GeneralSetting from "app/pages/GeneralSettings/language.de";
import ImportAbnormalityWidget from "app/pages/Home/Widgets/ImportedAbnormality/language.de";
import Map from "app/components/PantoMap/language.de";
import NewEventsWidget from "app/pages/Home/Widgets/NewEvents/language.de";
import Pantographs from "app/pages/Pantographs/language.de";
import Pantovision from "app/pages/Pantovision/language.de";
import PointDetail from "app/pages/SuperPower/Pointdetail/language.de";
import PointStatusSummeryWidget from "app/pages/Home/Widgets/PointsStatusSummery/language.de";
import ServerLogWidget from "app/pages/Home/Widgets/ServerLog/language.de";
import Simulation from "app/pages/Catenary/language.de";
import SuperPower from "app/pages/SuperPower/language.de";
import TriggerAlarm from "app/pages/Home/Widgets/TriggerAlarms/language.de";
import Views from "../views/language.de";
import Widgets from "app/pages/Home/language.de";
import PanelLayout from "app/panelLayout/language.de";
import Components from "app/components/language.de";
import Trigger from "app/pages/Triggers/language.de";
// import CatenaryPointsPdf from "app/pages/CatenaryPoints/Content/Points/language.de";

import CalendarTripItalian from "app/pages/CalendarTrips/language.it";
import CalendarTripsWidgetItalian from "app/pages/Home/Widgets/CalendarTrips/language.it";
import CatenaryPointsItalian from "app/pages/CatenaryPoints/language.it";
import CatenaryPointSummaryWidgetItalian from "app/pages/Home/Widgets/PointsSummery/language.it";
import DeviceOnTrainWidgetItalian from "app/pages/Home/Widgets/DeviceOnTrains/language.it";
import DeviceSettingItalian from "app/pages/DeviceSettings/language.it";
import GeneralSettingItalian from "app/pages/GeneralSettings/language.it";
import ImportAbnormalityWidgetItalian from "app/pages/Home/Widgets/ImportedAbnormality/language.it";
import MapItalian from "app/components/PantoMap/language.it";
import NewEventsWidgetItalian from "app/pages/Home/Widgets/NewEvents/language.it";
import PantographsItalian from "app/pages/Pantographs/language.it";
import PantovisionItalian from "app/pages/Pantovision/language.it";
import PointDetailItalian from "app/pages/SuperPower/Pointdetail/language.de";
import PointStatusSummeryWidgetItalian from "app/pages/Home/Widgets/PointsStatusSummery/language.it";
import ServerLogWidgetItalian from "app/pages/Home/Widgets/ServerLog/language.it";
import SimulationItalian from "app/pages/Catenary/language.it";
import SuperPowerItalian from "app/pages/SuperPower/language.it";
import TriggerAlarmItalian from "app/pages/Home/Widgets/TriggerAlarms/language.it";
import ViewsItalian from "../views/language.it";
import WidgetsItalian from "app/pages/Home/language.it";
import PanelLayoutItalian from "app/panelLayout/language.it";
import ComponentsItalian from "app/components/language.it";
import TriggerItalian from "app/pages/Triggers/language.it";

import en from "translations/en.json";
import de from "translations/de.json";
import it from "translations/it.json";

i18n.use(initReactI18next).init({
  ns: ["translation", "pdfReport"],
  resources: {
    en,
    de: {
      ...de,
      ...ImportAbnormalityWidget,
      ...CalendarTrip,
      ...CalendarTripsWidget,
      ...CatenaryPoints,
      ...CatenaryPointSummaryWidget,
      ...DeviceOnTrainWidget,
      ...DeviceSetting,
      ...GeneralSetting,
      ...NewEventsWidget,
      ...Pantographs,
      ...Pantovision,
      ...PointStatusSummeryWidget,
      ...ServerLogWidget,
      ...Simulation,
      ...PointDetail,
      ...TriggerAlarm,
      ...Views,
      ...Widgets,
      ...Map,
      ...SuperPower,
      ...PanelLayout,
      ...Components,
      ...Trigger,
      // ...CatenaryPointsPdf,
    },
    it: {
      ...it,
      ...ImportAbnormalityWidget,
      ...CalendarTripItalian,
      ...CalendarTripsWidgetItalian,
      ...CatenaryPointsItalian,
      ...CatenaryPointSummaryWidgetItalian,
      ...ImportAbnormalityWidgetItalian,
      ...DeviceOnTrainWidgetItalian,
      ...DeviceSettingItalian,
      ...GeneralSettingItalian,
      ...NewEventsWidgetItalian,
      ...PantographsItalian,
      ...PantovisionItalian,
      ...PointStatusSummeryWidgetItalian,
      ...ServerLogWidgetItalian,
      ...SimulationItalian,
      ...PointDetailItalian,
      ...TriggerAlarmItalian,
      ...ViewsItalian,
      ...WidgetsItalian,
      ...MapItalian,
      ...SuperPowerItalian,
      ...PanelLayoutItalian,
      ...ComponentsItalian,
      ...TriggerItalian,
    },
  },
  lng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
