export default {
  catenary_point: {
    "At risk": "A rischio",
    "Catenary point filter": "Filtro punti catenaria",
    "Checked points": "Punti controllati",
    "Choose a specific trip & time": "Scegli un viaggio e un orario specifico",
    "City Name": "Nome della città",
    "Download Signal": "Scarica segnale",
    "Downloading...": "Scaricamento...",
    "Dropper drop": "Caduta del pendino",
    "Dropper rupture": "Rottura del pendino",
    "Abnormality/Total": "Anomalia/Totale",
    "False alarm": "Falso allarme",
    "Fault category": "Categoria di guasto",
    "Go to check": "Vai a controllare",
    "Ignored points": "Punti ignorati",
    "Make a report": "Crea un rapporto",
    "Need repair": "Riparazione necessaria",
    "Need to repair": "Riparazione necessaria",
    "Need to check on": "Necessita di controllo",
    "No.of abnormality": "Numero di anomalie",
    "NoNeed repair": "Nessuna riparazione necessaria",
    "No Need to repair": "Nessuna riparazione richiesta",
    "Not Recorded": "Non registrato",
    "Not Requested": "Non richiesto",
    "Overhead drop": "Caduta dall'alto",
    "Point Detail": "Dettaglio punto",
    "Point status": "Stato del punto",
    "Request Seen": "Richiesta visualizzata",
    "Request Video": "Richiedi video",
    "Scratched C.W": "C.W graffiato",
    "Shock Point": "Punto d'urto",
    "Sorry, this signal doesn't have data":
      "Spiacente, questo segnale non contiene dati",
    "Sort by": "Ordina per",
    "Type/Total": "Tipo/Totale",
    "Video Requested": "Video richiesto",
    "Zig-Zag": "Zig-Zag",
    Cantilever: "Mensola",
    Checked: "Controllato",
    Corrosion: "Corrosione",
    Counts: "Conteggi",
    Created: "Creato",
    abnormalities: "Anomalie",
    Favorite: "Preferito",
    found: "trovato",
    Height: "Altezza",
    Ignore: "Ignora",
    Joint: "Giunto",
    Last: "Ultimo",
    New: "Nuovo",
    No: "No",
    Other: "Altro",
    Overlap: "Sovrapposizione",
    points: "Punti",
    Points: "Punti",
    Railways: "Ferrovie",
    Received: "Ricevuto",
    Repaired: "Riparato",
    Requested: "Richiesto",
    Requesting: "Richiesta in corso",
    Sections: "Sezioni",
    Speed: "Velocità",
    Status: "Stato",
    Time: "Tempo",
    Total: "Totale",
    Type: "Tipo",
    Unhealthy: "Danneggiato",
    Wearing: "Usura",
    "imported data": "dati importati",
    "please check mark a row(s) to make report":
      "Seleziona una o più righe per creare un rapporto.",
    "Load All": "Carica tutto",
    "Are you sure you want to delete this abnormality?":
      "Sei sicuro di voler eliminare questa anomalia?",
    "Delete Abnormality": "Elimina anomalia",
    Delete: "Elimina",
    Cancel: "Annulla",
    "The abnormality could not be removed.":
      "L'anomalia non può essere rimossa.",
    "something went wrong.": "qualcosa è andato storto",
    "Imported abnormality was removed successfully":
      "L'anomalia importata è stata rimossa con successo",
    "Abnormality removed": "Anomalia rimossa",
    "Archived Points": "Punti archiviati",
    Favorites: "Preferiti",
    "Make Report": "Crea rapporto",
    time: "Tempo",
    speed: "Velocità",
    "Possibility of Problem": "Possibilità di problema",
    "Created Time": "Ora di creazione",
    "Updated Time": "Ora di aggiornamento",
    Impact: "Impatto",
    Thickness: "Spessore",
    Force: "Forza",
    "zig zag": "zig zag",
    Arc: "Arco",
    "Abnormality type": "Tipo di anomalia",
    Nothing: "Niente",
    "Section Insulator": "Isolatore di sezione",
    "Mast (tower)": "Palo (torre)",
    Crossing: "Incrocio",
    Clamp: "Morsetto",
    Curve: "Curva",
    "Turn out": "Scambio",
    Suspicious: "Sospetto",
    "Health status": "Statodi salute",
    "No Need to Repair": "Nessuna riparazione necessaria",
    "Go Check": "Vai a controllare",
    "Need to Repair": "Necessita di riparazione",
    "No Status": "Nessuno stato",
    "Maintenance status": "Stato manutenzione",
    "No Data": "Nessun dato",
    "fault category": "categoria di guasto",
    "point status": "stato del punto",
    "Upload pic": "Carica immagine",
    "The image could not be imported.": "L'immagine non può essere importata.",
    created: "Creato",
    "Checking...": "Verifica in corso...",
    "No action yet!": "Nessuna azione ancora!",
    "Check Date": "Controlla data",
    "checked by": "Controllato da",
    "Search location...": "Cerca posizione...",
    success: "Successo",
    "Point is": "Il punto è",
    Archived: "Archiviato",
    Unarchived: "Non archiviato",
    archived: "archiviato",
    unarchived: "non archiviato",
    error: "Errore",
    "View detail": "Visualizza dettagli",
    "Go check": "Vai a controllare",
    Archive: "Archivia",
    Unarchive: "Rimuovi archivio",
    "Copied:": "Copiato:",
    Copy: "Copia",
    "The number should be less than 1000":
      "Il numero deve essere inferiore a 1000",
    "1Y": "1A",
    All: "Tutti",
    "Validation Error": "Errore di validazione",
    "Please select or filter points before generating a report!":
      "Seleziona o filtra i punti prima di generare un rapporto!",
    "Filtering for the last six weeks": "Filtraggio ultime sei settimane",
    "Filtering for the last six months": "Filtraggio ultimi sei mesi",
    "Filtering for the last six years": "Filtraggio ultimi sei anni",
    "Point status:": "Stato del punto:",
    Processing: "Elaborazione",
    Corrupted: "Corrotto",
    "Video Deleted": "Video eliminato",
    "Sorted by Time": "Ordinato per tempo",
    "Loading more...": "Caricamento in corso...",
    "Please select a maintenance status.":
      "Seleziona uno stato di manutenzione.",
    "Something went wrong": "Qualcosa è andato storto",
    "Image upload failed. Please try again.":
      "Caricamento immagine fallito. Riprova.",
    "Image has been uploaded successfully":
      "L'immagine è stata caricata con successo",
    Save: "Salva",
    "Make a Checked Status": "Crea stato controllato",
    "No need to repair": "Nessuna riparazione necessaria",
    "Description:": "Descrizione:",
    "Choose Status:": "Scegli stato:",
    "Counts:": "Conteggi:",
    Map: "Mappa",
    "No data": "Nessun dato",
    "Report name": "Nome rapporto",
    "Junction (crossroad)": "Incrocio",
    Set: "Imposta",
    "Select all for make report": "Seleziona tutto per creare il rapporto",
    "Report name cannot exceed 150 characters":
      "Il nome del report non può superare i 150 caratteri",
    "Description cannot exceed 574 characters":
      "La descrizione non può superare i 574 caratteri",
    "Download PDF": "Scarica PDF",
    Language: "Lingua",
  },
};
