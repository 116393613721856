export default {
  general_setting: {
    Monitoring: "Monitoraggio",
    From: "Da",
    To: "A",
    Simulation: "Simulazione",
    "Remain credits": "Crediti rimanenti",
    Enterprise: "Azienda",
    Premium: "Premium",
    Community: "Comunità",
    "No user found": "Nessun utente trovato",
    Name: "Nome",
    Position: "Posizione",
    Role: "Ruolo",
    "Last online": "Ultimo accesso",
    "Edit Company": "Modifica azienda",
    Disabled: "Disabilitato",
    "Add Company": "Aggiungi azienda",
    "Add User": "Aggiungi utente",
    UserName: "Nome utente",
    "Upgrade Plan": "Piano di upgrade",
    "Upgrade request sent!": "Richiesta di upgrade inviata!",
    "The support team will contact you as soon as possible.":
      "Il team di supporto ti contatterà il prima possibile.",
    "Sorry, we couldn't upgrade!":
      "Spiacenti, non siamo riusciti a fare l'upgrade!",
    "Try again later.": "Riprovare più tardi.",
    "Emails address": "Indirizzo email",
    "Train speed more than": "Velocità treno superiore a",
    "More than": "Più di",
    "have passed since the previous trip":
      "sono passati dalla precedente corsa",
    min: "min",
    "have passed since the last GPS data": "sono passati dall'ultimo dato GPS",
    "Train speed considered zero when GPS speed is less than":
      "La velocità del treno è considerata zero quando la velocità GPS è inferiore a",
    "Point Meaning": "Significato del punto",
    "Close abnormality distance": "Distanza anomalie chiusa",
    Gps: "Gps",
    "Minimum Speed": "Velocità minima",
    "Import data": "Importa dati",
    "Trip Meaning": "Significato del viaggio",
    "Total meaning": "Significato totale",
    "Abnormality meaning": "Significato dell'anomalia",
    "Critical errors": "Errori critici",
    "Simulation custom catenary": "Simulazione della catenaria personalizzata",
    "Advanced setting": "Impostazioni avanzate",
    "Ignore GPS point if": "Ignora punto GPS se",
    "The width of the range rectangle":
      "Larghezza del rettangolo di intervallo",
    "The new abnormality takes place": "La nuova anomalia si verifica",
    "at the previous point, if": "al punto precedente, se",
    "Something went wrong": "Qualcosa è andato storto",
    "The data could not be added.": "I dati non possono essere aggiunti.",
    "Data added": "Dati aggiunti",
    "The data added successfully.": "I dati sono stati aggiunti con successo.",
    "The data could not be removed.": "I dati non possono essere rimossi.",
    "Data removed": "Dati rimossi",
    "The data removed successfully.": "I dati sono stati rimossi con successo.",
    No: "No",
    Yes: "Sì",
    "Delete data": "Elimina dati",
    "Are you sure you want to delete data?":
      "Sei sicuro di voler eliminare i dati?",
    "Import abnormalities": "Importa anomalie",
    "Import signals": "Importa segnali",
    Note: "Nota",
    "Add Data": "Aggiungi dati",
    "Remove data": "Rimuovi dati",
    "download data": "Scarica dati",
    "Height abnormality": "Anomalia altezza",
    "Height more than": "Altezza maggiore di",
    "Height less than": "Altezza inferiore a",
    "Zig-zag abnormality": "Anomalia Zig-zag",
    "Zig-zag more than": "Zig-zag maggiore di",
    "Zig-zag less than": "Zig-zag inferiore a",
    "Acc abnormality": "Anomalia Acc",
    "maximum compression ratio": "Rapporto di compressione massimo",
    "Impact duration": "Durata dell'impatto",
    "search to find abnormality until": "Cerca per trovare anomalie fino a",
    fails: "fallisce",
    "Force abnormality": "Forza anomalia",
    "Wearing abnormality": "Anomalia di usura",
    "wearing less than": "usura inferiore a",
    "In same trip and with same type minimum distance that two abnormalities should have from each other":
      "Nella stessa corsa e con la stessa tipologia, la distanza minima che due anomalie dovrebbero avere l'una dall'altra",
    "Company updated successfully": "Azienda aggiornata con successo",
    "Company added successfully": "Azienda aggiunta con successo",
    "Validation Errors": "Errori di validazione",
    "Enter company name": "Inserisci il nome dell'azienda",
    "Select a map access": "Seleziona un accesso alla mappa",
    "Select a panel access": "Seleziona un accesso al pannello",
    "Add a history entry": "Aggiungi una voce di cronologia",
    "Sorry, this password is incorrect :(":
      "Spiacenti, questa password è errata :(",
    "Company deleted successfully!": "Azienda eliminata con successo!",
    "Company not found!": "Azienda non trovata!",
    "Something went wrong!": "Qualcosa è andato storto!",
    "Are you sure you want to enable this company?":
      "Sei sicuro di voler abilitare questa azienda?",
    "Company enabled": "Azienda abilitata",
    "Are you sure you want to disable this company?":
      "Sei sicuro di voler disabilitare questa azienda?",
    "Company disabled": "Azienda disabilitata",
    Delete: "Elimina",
    Enable: "Abilita",
    Disable: "Disabilita",
    Cancel: "Annulla",
    Save: "Salva",
    Add: "Aggiungi",
    "Contact person information": "Informazioni sulla persona di contatto",
    "First name": "Nome",
    "Last name": "Cognome",
    "Phone number": "Numero di telefono",
    "Company name": "Nome dell'azienda",
    "Map access": "Accesso alla mappa",
    Select: "Seleziona",
    What: "Cosa",
    Credit: "Credito",
    Plan: "Piano",
    "No history": "Nessuna cronologia",
    Devices: "Dispositivi",
    "Web address": "Indirizzo web",
    "Catenary points": "Punti di catenaria",
    "Calendar & trips": "Calendario & viaggi",
    Pantographs: "Pantografi",
    Triggers: "Attivatori",
    Reporting: "Segnalazione",
    Range: "Intervallo",
    Threshold: "Soglia",
    max: "max",
    "choose company": "scegli azienda",
    "set thresholds": "imposta soglie",
    Acceleration: "Accelerazione",
    Height: "Altezza",
    Zigzag: "Zigzag",
    Arc: "Arco",
    f2: "f2",
    Force: "Forza",
    "Cross distance": "Distanza trasversale",
    "Cable remain": "Rimanenza del cavo",
    "Threshold added": "Soglia aggiunta",
    "The threshold could not be set.": "La soglia non può essere impostata.",
    "The threshold set successfully.":
      "La soglia è stata impostata con successo.",
    "There is no point to add a status":
      "Non c'è nessun punto per aggiungere uno stato",
    "Please select a maintenance status.":
      "Seleziona uno stato di manutenzione.",
    "Something Went Wrong! Please Try Again Later":
      "Qualcosa è andato storto! Per favore riprova più tardi.",
    "Point status added": "Stato del punto aggiunto",
    "Point status has been added successfully":
      "Lo stato del punto è stato aggiunto con successo",
    "Image upload failed. Please try again.":
      "Caricamento immagine fallito. Per favore riprova.",
    "Image is uploaded": "L'immagine è caricata",
    "Image has been uploaded successfully":
      "L'immagine è stata caricata con successo",
    "The maximum allowed size for uploaded images is 5mb!":
      "La dimensione massima consentita per le immagini caricate è di 5 MB!",
    "Image is deleted": "Immagine eliminata",
    "Image has been deleted successfully": "Immagine eliminata con successo",
    "Make a Checked Status": "Crea uno stato verificato",
    "No need to repair": "Non è necessaria riparazione",
    "Need to repair": "Riparazione necessaria",
    Repaired: "Riparato",
    "Description:": "Descrizione:",
    "Load more": "Carica di più",
    "Loading...": "Caricamento...",
    "1Y": "1A",
    All: "Tutti",
    "Please select an OHL feature before submitting the report.":
      "Seleziona una funzione OHL prima di inviare il rapporto.",
    "Please select a defect before submitting the report.":
      "Seleziona un difetto prima di inviare il rapporto.",
    "Please select a time before submitting the report.":
      "Seleziona un orario prima di inviare il rapporto.",
    "Time frame cannot be set to 0.":
      "L'intervallo di tempo non può essere impostato su 0.",
    "Error saving the report. Please try again.":
      "Errore nel salvataggio del rapporto. Riprova.",
    "The report has been updated successfully.":
      "Il rapporto è stato aggiornato con successo.",
    "Configuration updated": "Configurazione aggiornata",
    "Select...": "Seleziona...",
  },
};
