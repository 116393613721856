export default {
  catenary_point_summary: {
    "Favorite points": "Hot-Spots",
    "Need to attention": "Aktion erforderlich",
    "Go to check points": "Zu den Kontrollpunkten gehen",
    "Checked points": "Punkte",
    "No. Events": "Anzahl Ereignisse",
    "No. points": "Anzahl Punkte",
    "Merged points": "Gekoppelte Punkte",
    "Created report": "Erstellter Bericht",
    "Requested video": "Gewünschtes Video",
    "Download signal": "Signal herunterladen",
    "See on map": "Auf der Karte sehen",
    Yearly: "Jährlich",
    Monthly: "Monatlich",
    Weekly: "Wöchentlich",
    "1Y": "1J",
  },
};
