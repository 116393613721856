export default {
  map: {
    "Your Location": "La tua posizione",
    Satellite: "Satellite",
    "Make a sections": "Crea sezioni",
    "Make a section": "Crea una sezione",
    Line: "Linea",
    "Fit to country": "Adatta al paese",
    Finish: "Fine",
    "Delete section": "Elimina sezione",
    Add: "Aggiungi",
    Edit: "Modifica",
    "Make Report": "Crea rapporto",
    Cancel: "Annulla",
    "Add Section": "Aggiungi sezione",
    "Edit Section": "Modifica sezione",
    "Section name": "Nome della sezione",
    State: "Stato",
    Railway: "Ferrovia",
    Country: "Paese",
    Station: "Stazione",
    Company: "Azienda",
    "Section Type": "Tipo di sezione",
    "Sections Types": "Tipi di sezioni",
    Copied: "Copiato",
    Copy: "Copia",
    "Make an abnormality": "Crea un'anomalia",
    "Contact wire height overrun": "Superamento altezza filo di contatto",
    "Contact wire zig-zag overrun": "Superamento zig-zag filo di contatto",
    "Occurrence of Auflauf": "Occorrenza di accumulo",
    "Contact wire wearing": "Usura del filo di contatto",
    "Occurrence of arc on carbon strip":
      "Presenza di arco sulla striscia di carbonio",
    "Contact force overrun": "Superamento della forza di contatto",
    "Occurrence of impact signal": "Presenza di segnale d'impatto",
    Unavailable: "Non disponibile",
    error: "Errore",
    "Something went wrong": "Qualcosa è andato storto",
    "The image could not be imported.": "L'immagine non può essere importata.",
    "Your account doesn't assign to any device":
      "Il tuo account non è assegnato a nessun dispositivo.",
    success: "Successo",
    "Abnormality added": "Anomalia aggiunta",
    "The abnormality added successfully.":
      "L'anomalia è stata aggiunta con successo.",
    "If you sort abnormality by time you can find easily this abnormality.":
      "Se ordini le anomalie per tempo, puoi trovarle facilmente.",
    warning: "Avviso",
    "you didn't enter value.": "Non hai inserito un valore.",
    "The abnormality added successfully":
      "L'anomalia è stata aggiunta con successo.",
    "The abnormality could not be added.":
      "L'anomalia non può essere aggiunta.",
    "Make abnormality": "Crea anomalia",
    "No need to repair": "Nessuna riparazione necessaria",
    "Need to repair": "Riparazione necessaria",
    Repaired: "Riparato",
    "Scratched C.W": "Graffiato C.W.",
    "Dropper rupture": "Rottura del pendino",
    "Dropper drop": "Caduta del pendino",
    "Overhead drop": "Caduta della linea aerea",
    Tension: "Tensione",
    "Wire wearing": "Usura del filo",
    "Wire height overrun": "Superamento altezza filo",
    "Wire zig-zag overrun": "Superamento zig-zag filo",
    Arc: "Arco",
    Auflauf: "Accumulo",
    Other: "Altro",
    "point status": "Stato del punto",
    "fault category": "Categoria di guasto",
    "Please set location access allowed":
      "Si prega di consentire l'accesso alla posizione",
    "The section could not be added": "La sezione non può essere aggiunta.",
    "Section added": "Sezione aggiunta",
    "The section added successfully.":
      "La sezione è stata aggiunta con successo.",
    "Section edited": "Sezione modificata",
    "The section edited successfully.":
      "La sezione è stata modificata con successo.",
    "The section could not be removed.": "La sezione non può essere rimossa.",
    "Section removed": "Sezione rimossa",
    "The section removed successfully.":
      "La sezione è stata rimossa con successo.",
    Description: "Descrizione",
    "The section could not be added.": "La sezione non può essere aggiunta.",
    "No points found :(": "Nessun punto trovato :(",
  },
};
