export default {
  calendar_trips_page: {
    "You should choose a trip, first":
      "Bitte wählen Sie zuerst eine FahrtReise aus",
    "Environment Temperature": "Umgebungstemperatur",
    Average: "Durchschnitt",
    Device: "Gerät",
    Date: "Datum",
    Sections: "Abschnitte",
    "City Name": "Stadtname",
    Railways: "Eisenbahnen",
    "Change trip": "Fahrt ändern",
    "Choose a trip": "Wählen Sie eine Fahrt",
    Supervision: "Beaufsichtigung",
    "Download Signal": "herunterladen",
    "No Signal": "Kein Signal",
    "Downloading...": "heruntergeladen...",
    "Request Video": "Video anfordern",
    Received: "Empfangen",
    "Not recorded": "Nicht aufgenommen",
    Requested: "Angefordert",
    "Requesting...": "Anfrage...",
    "Make a report": "Berichterstellung",
    "Sort by": "Sortieren nach",
    Sort: "Sortieren",
    "start->end": "anfang->ende",
    "end->start": "ende->anfang",
    "shock point": "Schockpunkt",
    std: "std",
    "First, you should choose a trip":
      "Zunächst sollten Sie sich für eine Reise entscheiden",
    "Trip Information": "Reiseinformationen",
    "please check mark a row(s) to make report":
      "Bitte markieren Sie eine oder mehrere Zeilen, um einen Bericht zu erstellen.",
    "Download List": "Liste herunterlade",
  },
};
