export default {
  supervision: {
    "Acc data number": "Numero dati di accesso",
    "Acceleration STD": "Accelerazione STD",
    "Acceleration-Miss data": "Dati accelerazione-Miss",
    "Add a figure": "Aggiungi una figura",
    "Amir filter": "Filtro Amir",
    "Device Name": "Nome dispositivo",
    "Download Signals": "Scaricare segnali",
    "Download Video": "Scaricare video",
    "Figure property": "Proprietà della figura",
    "Figure type": "Tipo di figura",
    "Impact time": "Tempo di impatto",
    "Impact Signal": "Segnale di impatto",
    "Import Video": "Importa video",
    "Make report": "Crea report",
    "Max comparison ratio": "Rapporto massimo di confronto",
    "Not found map points": "Punti mappa non trovati",
    "Not Recorded": "Non registrato",
    "Not Requested": "Non richiesto",
    "One minute summary": "Riepilogo di un minuto",
    "one minute video": "video di un minuto",
    "Seen by device": "Visto dal dispositivo",
    "Search to find abnormalities until": "Cerca per trovare anomalie fino a",
    "Signal filter": "Filtro del segnale",
    "Solar panel": "Pannello solare",
    "Sorry, this signal doesn't have data":
      "Mi dispiace, questo segnale non ha dati",
    "Train speed": "Velocità del treno",
    "Trip information": "Informazioni sul viaggio",
    "Wind turbine": "Turbina eolica",
    "Window length": "Lunghezza della finestra",
    "Window rms": "Finestra rms",
    "Zig-Zag": "Zig-Zag",
    Acceleration: "Accelerazione",
    Backward: "Indietro",
    Cancel: "Annulla",
    Copied: "Copiato",
    Copy: "Copia",
    "Point Location": "Posizione punto",
    "Open in Google Maps": "Apri in Google Maps",
    CT: "CT",
    Date: "Data",
    Download: "Scarica",
    "More Details": "Altri dettagli",
    Autofit: "Adattamento automatico",
    fails: "fallisce",
    Forward: "Avanti",
    Go: "Vai",
    "height (cm)": "altezza (cm)",
    Height: "Altezza",
    in: "in",
    Kur: "Kur",
    Max: "Max",
    Min: "Min",
    Ok: "Ok",
    out: "fuori",
    Outside: "Esterno",
    PANTOboard: "PANTOboard",
    Pause: "Pausa",
    Play: "Riproduci",
    Received: "Ricevuto",
    Remove: "Rimuovi",
    Rendering: "Rendering",
    Requested: "Richiesto",
    "Send to device": "Invia al dispositivo",
    Std: "Std",
    Temperature: "Temperatura",
    Thickness: "Spessore",
    Time: "Tempo",
    Video: "Video",
    "zigzag (cm)": "zigzag (cm)",
    "Point info": "Info punto",
    "Last status": "Ultimo stato",
    "Possibility of a problem": "Possibilità di un problema",
    "The shocks are severe": "Gli urti sono gravi",
    Previous: "Precedente",
    Next: "Successivo",
    "Trip info": "Info viaggio",
    Device: "Dispositivo",
    "No abnormalities found in this time":
      "Nessuna anomalia trovata in questo periodo",
    "History & Forecast": "Storia e previsione",
    "Go to check": "Vai a controllare",
    Checked: "Controllato",
    "Create new status": "Crea nuovo stato",
    "Point status removed!": "Stato punto rimosso!",
    "No action yet!": "Nessuna azione ancora!",
    "Go Check": "Controlla",
    "Need to Repair": "Necessita di riparazione",
    "No Need to Repair": "Non necessita di riparazione",
    Repaired: "Riparato",
    "Checking...": "Controllando...",
    Nothing: "Niente",
    "Section Insulator": "Isolatore sezione",
    "Junction (crossroad)": "Incrocio (strada)",
    "Mast (tower)": "Palo (torre)",
    Overlap: "Sovrapposizione",
    Crossing: "Incrocio",
    Clamp: "Morsetto",
    Curve: "Curva",
    "Turn out": "Svoltare",
    Other: "Altro",
    "Status history": "Storico stato",
    Type: "Tipo",
    "Something Went Wrong! Please Try Again Later":
      "Qualcosa è andato storto! Riprova più tardi",
    "Image has been uploaded successfully": "Immagine caricata con successo",
    "Image is uploaded": "Immagine caricata",
    "The maximum allowed size for uploaded images is 100 KB!":
      "La dimensione massima consentita per le immagini caricate è di 100 KB!",
    "Image is deleted": "Immagine eliminata",
    "Image has been deleted successfully": "Immagine eliminata con successo",
    "There is no point to add a status":
      "Non c'è alcun punto per aggiungere uno stato",
    "Point status has been added successfully":
      "Lo stato del punto è stato aggiunto con successo",
    "Point status added": "Stato punto aggiunto",
    "Make status": "Crea stato",
    Ignore: "Ignora",
    "False alarm": "Falso allarme",
    "Need to repair": "Necessita di riparazione",
    "No need to repair": "Nessuna riparazione necessaria",
    "Scratched C.W": "C.W graffiato",
    "Dropper rupture": "Rottura del dropper",
    "Dropper drop": "Caduta del dropper",
    "Overhead drop": "Caduta sospesa",
    Tension: "Tensione",
    Joint: "Giunto",
    Cantilever: "Braccio",
    "Rail Fault": "Guasto della rotaia",
    Description: "Descrizione",
    "point status": "stato punto",
    "fault category": "categoria del guasto",
    Upload: "Carica",
    History: "Storia",
    Future: "Futuro",
    "Please select a figure type": "Seleziona un tipo di figura",
    "Please select a figure option": "Seleziona un'opzione figura",
    "No abnormalities found in this time :(":
      "Nessuna anomalia trovata in questo periodo :(",
    "Point Information": "Informazioni punto",
    "prevSignal is not defined": "prevSignal non è definito",
    "nextSignal is not defined": "nextSignal non è definito",
    Creator: "Creatore",
    "Please set location access allowed": "Abilita l'accesso alla posizione",
    "Please find your location": "Trova la tua posizione",
    "Please select an abnormality": "Seleziona un'anomalia",
    Value: "Valore",
    Status: "Stato",
    Score: "Punteggio",
    Speed: "Velocità",
    "Upload image failed!": "Caricamento immagine fallito!",
    "Upload image succeeded!": "Caricamento immagine riuscito!",
    "Score:": "Punteggio:",
    "Time:": "Tempo:",
    more: "di più",
    "Loading more...": "Caricamento in corso...",
    "You can't remove this status!": "Non puoi rimuovere questo stato!",
    "No Abnormalities and statuses found in this time :(":
      "Nessuna anomalia e stato trovato in questo periodo :(",
    "Contact Wire Information": "Informazioni sul filo di contatto",
    "Video is deleted": "Video eliminato",
    "Video has been deleted successfully": "Video eliminato con successo",
    "Selected time frame:": "Intervallo di tempo selezionato",
    "Choose checked type:": "Scegli il tipo selezionato:",
    "Checked description:": "Descrizione selezionata:",
    "OHL Feature:": "Funzione OHL",
    "Can you see a defect?": "Vedi un difetto?",
    Yes: "Sì",
    No: "No",
    "Not sure": "Non sono sicuro",
    "Report OHL": "Segnala OHL",
    "The video uploaded successfully":
      "Il video è stato caricato con successo.",
    "Sorry, this video is not uploading":
      "Mi dispiace, questo video non si sta caricando.",
    "The request video send successfully.":
      "Il video richiesto è stato inviato con successo.",
    "You don't have access to see videos": "Non hai accesso ai video.",
    "Previous Frame": "Frame precedente",
    "Next Frame": "Frame successivo",
    "Video Not Recorded": "Video non registrato",
    "The Video is Not Accessible... Code: NR":
      "Il video non è accessibile... Codice: NR,",
    "Video Not Requested": "Video non richiesto",
    "Request Seen by device": "Richiesta vista dal dispositivo",
    "Video Corrupted": "Video danneggiato",
    "The Video is Not Accessible... Code: VC":
      "Il video non è accessibile... Codice: VC",
    "Request Sent to device": "Richiesta inviata al dispositivo",
    Processing: "Elaborazione",
    "Due to the limitations of the device, we can only have the video available for up to two weeks.":
      "A causa delle limitazioni del dispositivo, possiamo avere il video disponibile per un massimo di due settimane.",
    "we don't have highlight abnormality": "Non abbiamo anomalie evidenziate",
    "we don't have signal": "Non abbiamo segnale",
    "we don't have point": "Non abbiamo punto",
    "You don’t have access to pantovision": "Non hai accesso a Pantovision",
    "You don't have access to pantovision": "Non hai accesso a Pantovision.",
    "upload video": "Carica video",
    "video request": "Richiesta video",
    "Video Request": "Richiesta video",
    "Create status": "Crea stato",
    goToCheck: "Vai a controllare",
    "Point status history": "Storico stato punto",
    looked: "Guardato",
    "No need to repair yet": "Non è necessario riparare ancora",
    "User checked": "Utente controllato",
    "Visual inspection Checked": "Ispezione visiva controllata",
    "Loading...": "Caricamento...",
    "Detailed view of the": "Vista dettagliata di",
    "signal data": "dati del segnale",
    "Use your mouse scroll to zoom in": "Usa la rotella del mouse per zoomare",
    "Zoom out": "Zoom indietro",
    "Zoom in": "Zoom avanti",
    Delete: "Elimina",
    "Your report will be created": "Il tuo report sarà creato",
    "You can see the status of your report from the notifications bar.":
      "Puoi vedere lo stato del tuo report dalla barra delle notifiche.",
    "Setup report": "Imposta report",
    "Report name": "Nome del report",
    "Report type": "Tipo di report",
    "Report description": "Descrizione del report",
    "Please fill the required fields": "Compila i campi richiesti",
    "Already checked": "Già controllato",
    Unchecked: "Non controllato",
    Recheck: "Rivedi",
    Completed: "Completato",
    "Maximizes the importance": "Massimizza l'importanza",
    "Point Report": "Report punto",
    Please: "Per favore",
    Required: "Obbligatorio",
    Continue: "Continua",
    "View report": "Visualizza report",
    "Maintenance status": "Stato di manutenzione",
    Archive: "Archivia",
    Unarchive: "Ripristina",
    "Health status": "Statodi salute",
    "Go check": "Controlla",
    "By User": "Da utente",
    "Feature assigned": "Funzione assegnata",
    Feature: "Funzione",
  },
};
