export default {
  simulation: {
    "Static modeling": "Modellazione statica",
    "Static result": "Risultato statico",
    "Dynamic modeling": "Modellazione dinamica",
    "Dynamic result": "Risultato dinamico",
    "Simulation saved successfully": "Simulazione salvata con successo",
    "Error saving simulation": "Errore nel salvare la simulazione",
    "You are only allowed to read the data":
      "Sei autorizzato solo a leggere i dati",
    Setting: "Impostazioni",
    "Share this page": "Condividi questa pagina",
    Saving: "Salvataggio",
    Save: "Salva",
    "Catenary Name": "Nome della catenaria",
    "Oh, you have not sent a static request yet!":
      "Oh, non hai ancora inviato una richiesta statica!",
    "First, go to static modeling and send a new request":
      "Prima, vai alla modellazione statica e invia una nuova richiesta",
    "Go to static modeling": "Vai alla modellazione statica",
    "You can see the static configuration of your design as well as the required length/static force of each dropper":
      "Puoi vedere la configurazione statica del tuo progetto e la lunghezza/forza statica richiesta per ogni gancio",
    "Go to span view": "Vai alla vista della campata",
    "Go to full view": "Vai alla vista completa",
    "Go to heatmap": "Vai alla mappa termica",
    "Go to Analysis Section": "Vai alla sezione di analisi",
    "Dropper number": "Numero di ganci",
    "Dropper force": "Forza del gancio",
    "Dropper elasticity": "Elasticità del gancio",
    "Default catenary simulated": "Catenaria predefinita simulata",
    "Dropper length": "Lunghezza del gancio",
    Dropper: "Gancio",
    Force: "Forza",
    Elasticity: "Elasticità",
    Length: "Lunghezza",
    "Download excel file": "Scarica il file excel",
    "Oh, you have not sent a dynamic request yet!":
      "Oh, non hai ancora inviato una richiesta dinamica!",
    "First, go to dynamic modeling and send a new request":
      "Prima, vai alla modellazione dinamica e invia una nuova richiesta",
    "Go to dynamic modeling": "Vai alla modellazione dinamica",
    "Analysis Section": "Sezione di analisi",
    "Pantograph Moment": "Momento del pantografo",
    "Displacement (MM)": "Spostamento (MM)",
    "Velocity (M/S)": "Velocità (M/S)",
    "Acceleration (M/S2)": "Accelerazione (M/S2)",
    "Contact force (N)": "Forza di contatto (N)",
    "Pantograph's head": "Testa del pantografo",
    time: "Tempo",
    Backward: "Indietro",
    Pause: "Pausa",
    Play: "Gioca",
    Forward: "Avanti",
    "STD of contact force (0 Hz to 5 Hz)":
      "STD della forza di contatto (0 Hz a 5 Hz)",
    "STD of contact force (5 Hz to 20 Hz)":
      "STD della forza di contatto (5 Hz a 20 Hz)",
    "STD of contact force (0 Hz to 20 Hz)":
      "STD della forza di contatto (0 Hz a 20 Hz)",
    "STD (incl. low pass filter 20 Hz)": "STD (incl. filtro passa basso 20 Hz)",
    "Actual maximum of contact force": "Massimo reale della forza di contatto",
    "Actual minimum of contact force": "Minimo reale della forza di contatto",
    "Percentage of loss of contact": "Percentuale di perdita di contatto",
    "Maximum support uplift": "Massimo sollevamento del supporto",
    "Uplift at support": "Sollevamento al supporto",
    "Dropper Force": "Forza del gancio",
    Displacement: "Spostamento",
    Acceleration: "Accelerazione",
    Velocity: "Velocità",
    Pantograph: "Pantografo",
    Max: "Massimo",
    Min: "Minimo",
    Distance: "Distanza",
    "Analysis section from end of": "Sezione di analisi dalla fine di",
    to: "a",
    Apply: "Applica",
    "Request for custom pantograph model":
      "Richiesta per modello di pantografo personalizzato",
    "Pantograph property": "Proprietà del pantografo",
    "Manual input": "Inserimento manuale",
    "K contact (N/m)": "Contatto K (N/m)",
    "Auto input": "Inserimento automatico",
    "moving load": "carico mobile",
    "Force (N)": "Forza (N)",
    "Dynamic Response": "Risposta dinamica",
    "Load Dynamic Response": "Carica risposta dinamica",
    Uplift: "Sollevamento",
    force: "Forza",
    "Back to modeling": "Torna alla modellazione",
    "train speed": "velocità del treno",
    "Mechanical Properties": "Proprietà meccaniche",
    "Oh sorry, this calculation can't be solved!":
      "Oh scusa, questo calcolo non può essere risolto!",
    "The support team will contact you soon as possible.":
      "Il team di supporto ti contatterà il prima possibile.",
    "If there is something you can write in the chat section.":
      "Se c'è qualcosa, puoi scrivere nella sezione chat.",
    "Messenger Wire": "Cavo di supporto",
    "Messenger Wire Support": "Supporto cavo di supporto",
    "Steady Arm": "Braccio stabile",
    "Stitch Wire": "Cavo di cucitura",
    "Contact Wire": "Cavo di contatto",
    "Contact wire": "Cavo di contatto",
    "The number of": "Il numero di",
    "overlap spans": "Campate sovrapposte",
    between: "tra",
    and: "e",
    "is equal to": "è uguale a",
    Preview: "Anteprima",
    "First you should calculate static data":
      "Prima devi calcolare i dati statici",
    Calculate: "Calcola",
    Projects: "Progetti",
    "Estimated calculation time": "Tempo stimato di calcolo",
    Cancel: "Annulla",
    "Calculation started": "Calcolo avviato",
    "Request cancelled": "Richiesta annullata",
    "Error happened!": "Si è verificato un errore!",
    "Request for VR 4D film": "Richiesta per film VR 4D",
    Ok: "Ok",
    "*The damping of the other mode shapes will be considered based on the extrapolation of the assigned values":
      "*Il damping degli altri modi sarà considerato in base all'estrapolazione dei valori assegnati",
    "Mass of the clamp to catenary wire (kg)":
      "Massa della pinza sul cavo di catenaria (kg)",
    "Validation Warning": "Avviso di validazione",
    "the clamp is so light or too heavy, check the technical data":
      "La pinza è troppo leggera o troppo pesante, controlla i dati tecnici",
    "Validation Error": "Errore di validazione",
    "The weight of clamp can not be less than zero or more that 5kg":
      "Il peso della pinza non può essere inferiore a zero o superiore a 5 kg",
    "Tension per wire (kN)": "Tensione per cavo (kN)",
    "Young's modulus": "Modulo di Young",
    "the Young's modulus in messenger wire is 90-120 kN/mm2 normally":
      "Il modulo di Young nel cavo di supporto è normalmente 90-120 kN/mm2",
    "the value is not valid, check the technical data of the cable":
      "Il valore non è valido, controlla i dati tecnici del cavo",
    "Mass per length (kg/m)": "Massa per lunghezza (kg/m)",
    "the mass per unit length of this cable is 0.8-1.1 kg/m normally":
      "La massa per unità di lunghezza di questo cavo è normalmente 0,8-1,1 kg/m",
    "Cross section": "Sezione trasversale",
    "the cross section of this cable is 30-40 mm2 normally":
      "La sezione trasversale di questo cavo è normalmente 30-40 mm2",
    "Max frequency": "Frequenza massima",
    "*The maximum frequencies that will be considered in the simulation. The higher considered frequencies, the higher precision in results and the more required simulation time":
      "*Le frequenze massime che saranno considerate nella simulazione. Maggiore è la frequenza considerata, maggiore è la precisione nei risultati e maggiore è il tempo di simulazione richiesto",
    "Number of steady arms": "Numero di bracci stabili",
    "Number of steady arms are either 1 or 2!":
      "Il numero di bracci stabili è 1 o 2!",
    "the steady arm is so short or too long, check the technical data":
      "Il braccio stabile è troppo corto o troppo lungo, controlla i dati tecnici",
    "The length of steady arm can not be less than zero or more that 10":
      "La lunghezza del braccio stabile non può essere inferiore a zero o superiore a 10",
    "Mass per length (kg)": "Massa per lunghezza (kg)",
    "the mass per unit length of steady arm is 0.6-1.1 kg/m normally":
      "La massa per unità di lunghezza del braccio stabile è normalmente 0,6-1,1 kg/m",
    "Mass of clamp (kg)": "Massa della pinza (kg)",
    "The damping of steady arm can not be negative or high value.":
      "Il damping del braccio stabile non può essere negativo o elevato.",
    "Damping (N.s/m)": "Damping (N.s/m)",
    "The damping of steady arm can not be more than 100 N.s/m":
      "Il damping del braccio stabile non può essere superiore a 100 N.s/m",
    "Constant values": "Valori costanti",
    "Stiffness (kN/m)": "Rigidità (kN/m)",
    "The stiffness of the support is either low or high":
      "La rigidità del supporto è bassa o alta",
    "The stiffness of the support can not be less than 0 or more than 10000, if the support is rigid, please check the bottom above":
      "La rigidità del supporto non può essere inferiore a 0 o superiore a 10000, se il supporto è rigido, per favore controlla la parte sottostante",
    "Damping (Ns/m)": "Damping (Ns/m)",
    "The damping of the support is either low or high":
      "Il damping del supporto è basso o alto",
    "The damping of the support can not be less than 0 or more than 10000, if the support is rigid, please check the bottom above":
      "Il damping del supporto non può essere inferiore a 0 o superiore a 10000, se il supporto è rigido, per favore controlla la parte sottostante",
    "Load response": "Risposta al carico",
    Fixed: "Fisso",
    "Load dynamic response": "Risposta dinamica al carico",
    "the tension in messenger wire is 13-21kN normally":
      "La tensione nel cavo di supporto è normalmente 13-21 kN",
    "This linear analytical simulation is not suitable for extremely high tension or super low tension cables":
      "Questa simulazione analitica lineare non è adatta per cavi con tensione estremamente alta o bassa",
    "the cross section of this cable is 80-120 mm2 normally":
      "La sezione trasversale di questo cavo è normalmente 80-120 mm2",
    "This changes applied on dropper that created with our default values":
      "Questi cambiamenti sono stati applicati al gancio creato con i nostri valori predefiniti",
    "Stiffness and Damping": "Rigidità e Damping",
    "A rigid connection between contact wire and messenger cable will be considered":
      "Verrà considerata una connessione rigida tra il cavo di contatto e il cavo di supporto",
    "Young's modulus (kN/mm2)": "Modulo di Young (kN/mm2)",
    "The weight of clamp can not be less than zero or more that 3kg":
      "Il peso della pinza non può essere inferiore a zero o superiore a 3 kg",
    "Cross section (mm2)": "Sezione trasversale (mm2)",
    "the cross section of this cable is 5-50 mm2 normally":
      "La sezione trasversale di questo cavo è normalmente 5-50 mm2",
    "the mass per unit length of dropper is 0.6-0.2 kg/m normally":
      "La massa per unità di lunghezza del gancio è normalmente 0,6-0,2 kg/m",
    "Clamp on messenger wire (Kg)": "Pinza sul cavo di supporto (Kg)",
    "Clamp on contact wire (Kg)": "Pinza sul cavo di contatto (Kg)",
    "Using buckling theory": "Uso della teoria del collasso",
    "A spring with a bilinear stiffness between contact wire and messenger cable will be considered":
      "Verrà considerata una molla con rigidità bilineare tra il cavo di contatto e il cavo di supporto",
    "Max compression force (N)": "Forza massima di compressione (N)",
    "Assign a value": "Assegna un valore",
    "If you get the buckling threshold low, the calculation time would be too much. The High threshold, makes your dropper rigid":
      "Se imposti una soglia di collasso bassa, il tempo di calcolo sarà troppo lungo. La soglia alta rende il tuo gancio rigido",
    "The dropper stiffness in tension can not be less than zero.":
      "La rigidità del gancio sotto tensione non può essere inferiore a zero.",
    Analytical: "Analitico",

    "Stiffness in tension (N/m)": "Rigidità in tensione (N/m)",
    "the dropper stiffness is normally 100kN/m in tension, check the technical data":
      "La rigidità del dropper è normalmente di 100 kN/m in tensione, verifica i dati tecnici",
    "The dropper stiffness in tension can not be less than zero or more than 500kN/m":
      "La rigidità del dropper in tensione non può essere inferiore a zero o superiore a 500 kN/m",
    "Damping for compression (N.s/m)": "Smorzamento per compressione (N.s/m)",
    "The damping of dropper should not be too small or too high":
      "Lo smorzamento del dropper non dovrebbe essere troppo basso o troppo alto",
    "The damping of dropper cannot be negative or high value.":
      "Lo smorzamento del dropper non può essere negativo o di valore elevato.",
    "Damping for tension (N.s/m)": "Smorzamento per tensione (N.s/m)",
    "Stiffness for compression (N/m)": "Rigidità per compressione (N/m)",
    "the dropper stiffness is normally less than 100 tension, check the technical data":
      "La rigidità del dropper è normalmente inferiore a 100 tensione, verifica i dati tecnici",
    "Stiffness for tension (N/m)": "Rigidità per tensione (N/m)",
    "Add span": "Aggiungi span",
    "(first span)": "(prima span)",
    "(last span)": "(ultima span)",
    "Stitch wire tension": "Tensione filo di cucitura",
    "the tension of stitch wire is normally 10-40% of the tension of messenger cable":
      "La tensione del filo di cucitura è normalmente tra il 10 e il 40% della tensione del cavo portante",
    "the tension of stitch wire can not be zero or more than the tension of messenger cable":
      "La tensione del filo di cucitura non può essere zero o superiore alla tensione del cavo portante",
    "the length of stitch wire can not be zero or more than the length of span":
      "La lunghezza del filo di cucitura non può essere zero o superiore alla lunghezza dello span",
    "normally 2-4 droppers located in stitch wire":
      "normalmente 2-4 droppers situati nel filo di cucitura",
    "The messenger wire height can not be less than contact wire height":
      "L'altezza del cavo portante non può essere inferiore all'altezza del cavo di contatto",
    "The catenary encumbrance is 1.2-1.8 normally":
      "L'ingombro della catenaria è normalmente tra 1,2 e 1,8",
    "The contact wire height can not be negative or zero or more than messenger cable height":
      "L'altezza del cavo di contatto non può essere negativa, zero o superiore all'altezza del cavo portante",
    "consider the recommendation of EN 15273-2-2013":
      "Considera la raccomandazione della EN 15273-2-2013",
    "the length of span could not be negative or zero":
      "la lunghezza dello span non può essere negativa o zero",
    "Length of span is normally 27-63m":
      "La lunghezza dello span è normalmente tra 27 e 63 m",
    "Add Dropper": "Aggiungi Dropper",
    "The number of droppers cannot be less than zeros or too high":
      "Il numero di droppers non può essere inferiore a zero o troppo alto",
    "The stager can not be more than span length":
      "Il posizionamento non può essere maggiore della lunghezza dello span",
    "The stager is ±30 cm normally":
      "Il posizionamento è normalmente di ±30 cm",
    "individual setting for steady arm":
      "impostazione individuale per braccio fisso",
    "Messenger wire stagger": "Posizionamento del cavo portante",
    "Contact wire stagger": "Posizionamento del cavo di contatto",
    "New Catenary": "Nuova Catenaria",
    "Catenary Pool": "Piscina della Catenaria",
    "Catenary deleted successfully!": "Catenaria eliminata con successo!",
    "Something went wrong!": "Qualcosa è andato storto!",
    "Catenary duplicated successfully!": "Catenaria duplicata con successo!",
    "Catenary left successfully!": "Catenaria lasciata con successo!",
    "No catenaries found": "Nessuna catenaria trovata",
    "No selected": "Nessuna selezionata",
    CatenaryType: "Tipo di catenaria",
    Access: "Accesso",
    Sample: "Campione",
    unknow: "sconosciuto",
    "Created time": "Tempo di creazione",
    "Updated time": "Tempo di aggiornamento",
    "Created by": "Creato da",
    Permission: "Permesso",
    Duplicate: "Duplica",
    Leave: "Esci",
    Delete: "Elimina",
    "All Access": "Tutto l'accesso",
    Full: "Completo",
    Read: "Leggi",
    Write: "Scrivi",
    "All Catenary": "Tutte le catenarie",
    Stitched: "Cucito",
    Compound: "Composto",
    "View catenary": "Visualizza catenaria",
    "Catenary created": "Catenaria creata",
    "Catenary model": "Modello di catenaria",
    "Apply to all": "Applica a tutti",
    "New simple catenary": "Nuova catenaria semplice",
    "New stitched catenary": "Nuova catenaria cucita",
    "New compound catenary": "Nuova catenaria composta",
    "Request for custom catenary": "Richiesta per catenaria personalizzata",
    "Search between catenaries by name": "Cerca tra le catenarie per nome",
    "Stitched catenary": "Catenaria cucita",
    "The bilinear mode is not yet implemented":
      "La modalità bilineare non è ancora implementata",
    "Droppers cannot be positioned directly on the mast.":
      "I droppers non possono essere posizionati direttamente sul palo.",
    "The dropper can't be located on either previous dropper or out of considered span":
      "Il dropper non può essere posizionato né sul dropper precedente né fuori dalla span considerata",
    "Dropper Detail": "Dettaglio Dropper",
    "Rigid dropper": "Dropper rigido",
    "Distance from left-side dropper": "Distanza dal dropper lato sinistro",
    "Dropper is either too close to or so far from the previous dropper.":
      "Il dropper è troppo vicino o troppo lontano dal dropper precedente.",
    "The vertical position of contact wire":
      "La posizione verticale del cavo di contatto",
    "in the dropper location": "nella posizione del dropper",
    "in the dropper location (m)": "nella posizione del dropper (m)",
    "The presags of the contact wire in dropper points are less than 50mm normally":
      "Le saghe del cavo di contatto nei punti di dropper sono inferiori a 50 mm normalmente",
    "The presags of the contact wire in dropper points can not be more than 200mm":
      "Le saghe del cavo di contatto nei punti di dropper non possono essere superiori a 200 mm",
    "individual setting": "impostazione individuale",
    " A spring with a bilinear stiffness between contract wire and messenger cable will be considered":
      "Verrà considerata una molla con rigidità bilineare tra cavo di contrazione e cavo portante",
    "Bilinear stiffness spring for droppers":
      "Molla con rigidità bilineare per droppers",
    "Elasticity of overhead contact line at the dropper points":
      "Elasticità della linea di contatto aerea nei punti di dropper",
    "something in static model is changed, you will need to recalculate if you want to see updated results":
      "Qualcosa nel modello statico è cambiato, dovrai ricalcolare se vuoi vedere i risultati aggiornati",
    "Add Section Insulator": "Aggiungi isolatore di sezione",
    "Add Clamp": "Aggiungi morsetto",
    "Custom Property": "Proprietà personalizzata",
    "Choose from library": "Scegli dalla libreria",
    "Section Insulator Detail": "Dettaglio isolatore di sezione",
    "Project imported": "Progetto importato",
    "You can see the imported project": "Puoi vedere il progetto importato",
    "Invalid parameters": "Parametri non validi",
    "You must pass this fields: type, model_type, name, tags, share_token, static_modeling, dynamic_modeling":
      "Devi passare questi campi: tipo, tipo_modello, nome, tag, share_token, modellazione_statica, modellazione_dinamica",
    "You can only have 5 projects": "Puoi avere solo 5 progetti",
    "If you want create more, please contact us":
      "Se vuoi crearne di più, contattaci",
    "Project import failed": "Importazione del progetto fallita",
    "Please check your project data": "Verifica i dati del progetto",
    "Import Project": "Importa progetto",
    "If you want create more, contact us": "Se vuoi crearne di più, contattaci",
    "Catenary name": "Nome della catenaria",
    "Are you sure you want to delete this card?":
      "Sei sicuro di voler eliminare questa scheda?",
    unknown: "sconosciuto",
    "Do you want to duplicate": "Vuoi duplicare?",
    catenary: "catenaria",
    "New AC simple catenary": "Nuova catenaria AC semplice",
    "New AC stitched catenary": "Nuova catenaria AC cucita",
    "New DC simple catenary": "Nuova catenaria DC semplice",
    "New DC stitched catenary": "Nuova catenaria DC cucita",
    "Newest created": "Creato più recentemente",
    "Oldest created": "Creato più vecchio",
    "Recently updated": "Aggiornato recentemente",
    "Search between catenaries by nam": "Cerca tra le catenarie per nome",
    "Please enter all fields": "Si prega di inserire tutti i campi",
    "Something went wrong. Please try again":
      "Qualcosa è andato storto. Per favore riprova",
    "Project transfer completed successfully!":
      "Trasferimento del progetto completato con successo!",
    "Transfer Project": "Trasferisci progetto",
    Transfer: "Trasferimento",
    Origin: "Origine",
    Destination: "Destinazione",
    "404 - Not Found": "404 - Non trovato",
    "Maybe the link is wrong or the catenary has been deleted":
      "Forse il link è errato o la catenaria è stata eliminata",
    "Go to Simulations": "Vai alle simulazioni",
    "401 - No Access": "401 - Nessun accesso",
    "You don't have access to this catenary":
      "Non hai accesso a questa catenaria",
    "Please try again later": "Per favore riprova più tardi",
    "Something went wrong": "Qualcosa è andato storto",
    "Save Changes": "Salva modifiche",
    "Project Setting": "Impostazioni del progetto",
    "Back to Catenary Pool": "Torna al pool delle catenarie",
    "Export project as JSON": "Esporta progetto come JSON",
    "Error saving simulatio": "Errore nel salvataggio della simulazione",
    "Pantograph Identification": "Identificazione Pantografo",
    "Start Identification": "Avvia identificazione",
    "Model name must be alphanumeric and can include underscores or dashes":
      "Il nome del modello deve essere alfanumerico e può includere trattini bassi o trattini",
    "Model name must be between 3 and 50 characters":
      "Il nome del modello deve essere tra 3 e 50 caratteri",
    Estimate: "Stima",
    mean: "media",
    dynamic: "dinamico",
    model: "modello",
    detail: "dettaglio",
    Section: "Sezione",
    Span: "Span",
    "Download Forces": "Scarica forze",
    "Choose the type of data that you want":
      "Scegli il tipo di dati che desideri",
    Download: "Scarica",
    "Total Force": "Forza totale",
    "Stiffness Force": "Forza di rigidità",
    "Damping Force": "Forza di smorzamento",
    "Calculated Value": "Valore calcolato",
    Threshold: "Soglia",
    "Mean value of contact force": "Valore medio della forza di contatto",
    "STD of contact force (0Hz to 20Hz)":
      "STD della forza di contatto (0Hz a 20Hz)",
    "STD of contact force (5Hz to 20Hz)":
      "STD della forza di contatto (5Hz a 20Hz)",
    "STD of contact force (0Hz to 5Hz)":
      "STD della forza di contatto (0Hz a 5Hz)",
    "Max. vertical dis. of contact point":
      "Max. spostamento verticale del punto di contatto",
    "a: analyzed in time-domain": "a: analizzato nel dominio del tempo",
    "b: analyzed in frequency-domain":
      "b: analizzato nel dominio della frequenza",
    "STD: standard deviation": "STD: deviazione standard",
    "Analysis section result": "Risultato della sezione di analisi",
    "Vertical pantograph disp.": "Spostamento verticale del pantografo",
    "Download Video": "Scarica video",
    "Pantograph response": "Risposta del pantografo",
    "Catenary response": "Risposta della catenaria",
    "Contact analysis": "Analisi del contatto",
    "The overlap area is not modeled correctly. Static simulation will proceed, but dynamic simulation might not be possible.":
      "L'area di sovrapposizione non è modellata correttamente. La simulazione statica proseguirà, ma quella dinamica potrebbe non essere possibile.",
    Close: "Chiudi",
    "More Info": "Altre informazioni",
    "Track layout": "Layout del binario",
    "Sending request...": "Invio richiesta...",
    "Calculation started!": "Calcolo avviato!",
    Cant: "Sovralzo",
    "Add defect": "Aggiungi difetto",
    mode: "Modalità",
    "Max frequency static": "Frequenza massima statica",
    "Max frequency dynamic": "Frequenza massima dinamica",
    Tension: "Tensione",
    Compression: "Compressione",
    "Messenger wire": "Cavo portante",
    "Remove joint": "Rimuovi giunto",
    "Add a joint": "Aggiungi un giunto",
    "The length of span could not be negative or zero":
      "La lunghezza della campata non può essere negativa o zero",
    "Please enter a valid number for distance, mass and length.":
      "Per favore inserisci un numero valido per distanza, massa e lunghezza.",
    "The clamp cannot be placed on the dropper.":
      "Il morsetto non può essere posizionato sul gocciolatore.",
    "Invalid value": "Valore non valido",
    "The section insulator cannot be placed on the stitch wire":
      "L'isolatore di sezione non può essere posizionato sul filo di cucitura",
    "cannot be less than 0.": "non può essere inferiore a 0.",
    "Clamp Detail": "Dettaglio morsetto",
    Model: "Modello",
    "Suspention Type": "Tipo di sospensione",
    "Support elasticity": "Elasticità del supporto",
    "Support force": "Forza di supporto",
    "Messenger cable deflection": "Deflessione del cavo portante",
    "Dropper length calculated at commissioning temperature":
      "Lunghezza del gocciolatore calcolata alla temperatura di messa in servizio",
    "Pantograph & Catenary Video": "Pantografo e video della catenaria",
    "To setup the report, please recalculate your catenary just once.":
      "Per configurare il report, ricalcola la tua catenaria una sola volta.",
    "Download Report": "Scarica il report",
    "Setup Report": "Configura il report",
    "Special dropper": "Gocciolatore speciale",
    "Tension damping": "Smorzamento della tensione",
    "Speed of cut off damping": "Velocità di smorzamento dell'interruzione",
    "Compression damping": "Smorzamento della compressione",
    "Messanger Wire Support": "Supporto cavo portante",
    "Pantograph model": "Modello di pantografo",
    "Something went wrong, please try again later!":
      "Qualcosa è andato storto, per favore riprova più tardi!",
    "Your request is being calculated in the background, and corresponding results can be seen as soon as it comes.":
      "La tua richiesta viene calcolata in background, e i risultati corrispondenti possono essere visualizzati non appena disponibili.",
    "Length (m)": "Lunghezza (m)",
    "Masse (kg)": "Massa (kg)",
    "Length between suspension (m)": "Lunghezza tra sospensioni (m)",
    Regid: "Rigido",
    Bilinear: "Biliniar",
    "Buckling theory": "Teoria del collasso",
    "Mass (Kg)": "Massa (kg)",
    "Use Defaults": "Usa valori predefiniti",
    Modify: "Modifica",
    "The mechanical properties are not modified.":
      "Le proprietà meccaniche non sono modificate.",
    "Do you want to use the default values?":
      "Vuoi usare i valori predefiniti?",
    "Modifying mechanical properties": "Modifica delle proprietà meccaniche",
    "Geometric data changed!": "Dati geometrici modificati!",
    "Please check zigzag, messenger cable and contact wire values.":
      "Per favore, verifica i valori di zigzag, cavo portante e cavo di contatto.",
    "Default model": "Modello predefinito",
    "Calculation Time:": "Tempo di calcolo:",
    "registration arm": "Braccio di registrazione",
    "Add a joint dropper": "Aggiungi un giunto gocciolatore",
    "Remove joint dropper": "Rimuovi giunto gocciolatore",
    "compare with": "confronta con",
    CompareWith: "Confronta con",
    "Add Section": "Aggiungi sezione",
    "Enter a valid name for catenary":
      "Inserisci un nome valido per la catenaria",
    "Please enter a model": "Per favore, inserisci un modello",
    "Uniform tension on messenger cable": "Tensione uniforme sul cavo portante",
    "Non uniform tension on messenger cable":
      "Tensione non uniforme sul cavo portante",
    "Simulation Name": "Nome della simulazione",
    "Users Access": "Accesso utenti",
    "Select user": "Seleziona utente",
    "Can view": "Può visualizzare",
    "Can edit": "Può modificare",
    Name: "Nome",
    Accessibility: "Accessibilità",
    "Last editing": "Ultima modifica",
    you: "Tu",
    Owner: "Proprietario",
    "Simulation name is required": "Il nome della simulazione è obbligatorio",
    "Simulation updated successfully": "Simulazione aggiornata con successo",
    "Simulation Setting": "Impostazioni simulazione",
    Update: "Aggiorna",
    "Estimating required time for the dynamic simulation. Please wait...":
      "Stima del tempo necessario per la simulazione dinamica. Attendere prego...",
    "By sending a new dynamic request": "Invio di una nuova richiesta dinamica",
    "the previous static result data will be deleted!":
      "i dati dei risultati statici precedenti verranno eliminati!",
    "Cancel Request": "Annulla richiesta",
    "You will be redirected to the results page shortly.":
      "Sarai reindirizzato alla pagina dei risultati a breve.",
    "Dynamic calculation warning": "Avviso di calcolo dinamico",
    "Calculating dynamic data...": "Calcolando i dati dinamici...",
    "Estimated Value:": "Valore stimato:",
    "Note: The estimated value is for the segment":
      "Nota: Il valore stimato è per il segmento",
    "of the contact wire with consistent height.":
      "del filo di contatto con altezza costante.",
    "Please re-calculate the static modeling.":
      "Per favore, ricalcola la modellazione statica.",
    "Please fill in all fields.": "Per favore, compila tutti i campi.",
    "Your request has been successfully sent":
      "La tua richiesta è stata inviata con successo",
    Send: "Invia",
    "Request custom catenary": "Richiedi catenaria personalizzata",
    "Invalid data": "Dati non validi",
    "Sorry, the simulation report creation was unsuccessful":
      "Scusa, la creazione del report di simulazione non è riuscita",
    Setup: "Configurazione",
    "Could not download file!": "Impossibile scaricare il file!",
    "Your simulation report is ready": "Il tuo report di simulazione è pronto",
    "Download the Report": "Scarica il report",
    "Please wait a few minutes": "Attendere qualche minuto",
    "Sort by": "Ordina per",
    "Saving changes...": "Salvataggio modifiche...",
    "Changes saved successfully!": "Modifiche salvate con successo!",
    "Failed to save changes. Please try again!":
      "Impossibile salvare le modifiche. Per favore riprova!",
    "Linear stiffness (N/m)": "Rigidità lineare (N/m)",
    "Compression Damping (N.s/m)": "Smorzamento in compressione (N.s/m)",
    "Tension Damping (N.s/m)": "Smorzamento in tensione (N.s/m)",
    "Speed of cut off Damping (m/s)":
      "Velocità di spegnimento dello smorzamento (m/s)",
    "Selecting this option": "Selezionando questa opzione",
    "Generates a simulation": "Genera una simulazione",
    "Video, which will increase": "Video, che aumenterà",
    "Video, calculation time considerably":
      "Video, che aumenterà significativamente il tempo di calcolo",
    "Structural damping in the wire (1/s)":
      "Smorzamento strutturale nel filo (1/s)",
    STATIC: "STATICO",
    DYNAMIC: "DINAMICO",
    VIDEO: "VIDEO",
    "Solved Dynamically": "Risolto Dinamicamente",
    "Solved Statically": "Risolto Staticamente",
    "Solved With Video": "Risolto Con Video",
    Solve: "Risolvi",
    "Solve Status": "Stato della Soluzione",
    More: "Più",
    Less: "Meno",
    options: "Opzioni",
  },
};
