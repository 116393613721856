export default {
  device_setting: {
    "Validation Error": "Errore di convalida",
    "Please enter a train name": "Per favore inserisci un nome del treno",
    "Please enter device name": "Per favore inserisci il nome del dispositivo",
    "Please choose a train": "Per favore scegli un treno",
    "Please enter api address": "Per favore inserisci l'indirizzo API",
    "Device created": "Dispositivo creato",
    "Device successfully created": "Dispositivo creato con successo",
    "Device updated": "Dispositivo aggiornato",
    "Device successfully updated": "Dispositivo aggiornato con successo",
    "Something went wrong": "Qualcosa è andato storto",
    "Try again later": "Riprova più tardi",
    Add: "Aggiungere",
    Edit: "Modifica",
    "Add Device": "Aggiungi dispositivo",
    UserName: "Nome utente",
    "Edit Device": "Modifica dispositivo",
    "Device Position on train": "Posizione del dispositivo sul treno",
    Position: "Posizione",
    "API address": "Indirizzo API",
    Gps: "GPS",
    Camera: "Fotocamera",
    Temperature: "Temperatura",
    Battery: "Batteria",
    Acceleration: "Accelerazione",
    Laser: "Laser",
    Train: "Treno",
    "Add new train": "Aggiungi nuovo treno",
    "The device access could not be removed.":
      "L'accesso al dispositivo non può essere rimosso.",
    "Device access removed": "Accesso al dispositivo rimosso",
    "The device access removed successfully.":
      "Accesso al dispositivo rimosso con successo.",
    "No user defined for this device":
      "Nessun utente definito per questo dispositivo",
    Name: "Nome",
    Role: "Ruolo",
    "The device access could not be added.":
      "L'accesso al dispositivo non può essere aggiunto.",
    "Device access added": "Accesso al dispositivo aggiunto",
    "The device access added successfully.":
      "Accesso al dispositivo aggiunto con successo.",
    "Device Access": "Accesso dispositivo",
    "Choose user": "Scegli utente",
    "Make report": "Crea rapporto",
    "Acceleration data": "Dati accelerazione",
    "System metric": "Metrica del sistema",
    "GPS data": "Dati GPS",
    Speed: "Velocità",
    Video: "Video",
    "Margin from above": "Margine dall'alto",
    "Max jump between frames": "Massimo salto tra i fotogrammi",
    "Wiper period fast": "Periodo tergicristallo veloce",
    "Wiper period slow": "Periodo tergicristallo lento",
    "Wiper period": "Periodo tergicristallo",
    "Please try again later.": "Per favore riprova più tardi.",
    "Device environment updated": "Ambiente del dispositivo aggiornato",
    "The environment data will be set": "I dati ambientali saranno impostati",
    "Device environment data": "Dati ambiente del dispositivo",
    "Real or test data": "Dati reali o di test",
    "real data": "dati reali",
    "test mode": "modalità test",
    "Video processing": "Elaborazione video",
    Wiper: "Tergicristallo",
    "Set on device": "Imposta sul dispositivo",
    "Installation Date": "Data di installazione",
    "Device Last Status": "Ultimo stato del dispositivo",
    "Last boot": "Ultimo avvio",
    "Last connect": "Ultima connessione",
    "Bytes sent": "Byte inviati",
    "Bytes recv": "Byte ricevuti",
    "CPU temperature": "Temperatura CPU",
    "CPU percent": "Percentuale CPU",
    "Memory percent used": "Percentuale memoria usata",
    "Disk percent used": "Percentuale disco usato",
    "Main Acc": "Principale Acc",
    "Solar Panel": "Pannello solare",
    Ultrasonic: "Ultrasonico",
    "Wind turbine": "Turbina eolica",
    "Train roof": "Tetto del treno",
    Outside: "Esterno",
    PANTObank: "PANTObank",
    PANTOcase: "PANTOcase",
    Temp: "Temp",
    Acc: "Acc",
    "Device ID": "ID dispositivo",
    "Not set": "Non impostato",
    Copied: "Copiato",
    Copy: "Copia",
    Loading: "Caricamento",
    "Installation Picture": "Immagine di installazione",
    Deleting: "Eliminando",
    Uploading: "Caricamento",
    "Calibration Video": "Video di calibrazione",
    "We support": "Supportiamo",
    "And size must be less than": "E la dimensione deve essere inferiore a",
    "Train speed": "Velocità del treno",
    "Sensors Calibration": "Calibrazione sensori",
    "Sensors calibration updated": "Calibrazione sensori aggiornata",
    "The calibration data will be set":
      "I dati di calibrazione saranno impostati",
    "Enable blur": "Abilita sfocatura",
    "Set Video Blur": "Imposta sfocatura video",
    Width: "Larghezza",
    Height: "Altezza",
    Save: "Salva",
    Cancel: "Annulla",
    "Device Color:": "Colore del dispositivo:",
    "An error occurred while creating the device":
      "Si è verificato un errore durante la creazione del dispositivo",
    "Device Enable": "Abilita dispositivo",
    "Device Name": "Nome del dispositivo",
    "Laser Calibration": "Calibrazione laser",
    "edit device": "modifica dispositivo",
    "Overall data video volume": "Volume dati video",
    "Operation time of device": "Tempo operazione dispositivo",
    "Device Last Statuse": "Ultimo stato dispositivo",
  },
};
