export default {
  widgets: {
    "Widget management": "Gestione dei widget",
    "New abnormality": "Nuova anomalia",
    "Trigger alarms": "Attivare allarmi",
    "Catenary points summary": "Riepilogo punti della catenaria",
    Reporting: "Report",
    "Points status": "Stato dei punti",
    "Device on trains": "Dispositivo sui treni",
    "Calendar & trips": "Calendario e viaggi",
    "User activity": "Attività utente",
    Pantographs: "Pantografi",
    "Data to server log": "Dati al registro del server",
    "Add widget": "Aggiungi widget",
    "Remove widget": "Rimuovi widget",
    Cancel: "Annulla",
    Ok: "Ok",
    "Imported abnormality": "Anomalia importata",
    Weekly: "Settimanale",
    Yearly: "Annuale",
    Daily: "Giornaliero",
    Monthly: "Mensile",
    "Something went wrong": "Qualcosa è andato storto",
    "The widget could not be removed.": "Il widget non può essere rimosso.",
    "widget added": "Widget aggiunto",
    "The widget added successfully.":
      "Il widget è stato aggiunto con successo.",
    "widget removed": "Widget rimosso",
    "The widget removed successfully.":
      "Il widget è stato rimosso con successo.",
    "Please select a widget to add": "Seleziona un widget da aggiungere",
    "You don't have access to add widgets":
      "Non hai accesso per aggiungere widget",
    Removing: "Rimozione",
    Add: "Aggiungi",
    Click: "Clicca",
    click: "clicca",
  },
};
