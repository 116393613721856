export default {
  catenary_point_summary: {
    "Favorite points": "Punti preferiti",
    "Need to attention": "Azione richiesta",
    "Go to check points": "Vai ai punti di controllo",
    "Checked points": "Punti controllati",
    "No. Events": "Numero eventi",
    "No. points": "Numero punti",
    "Merged points": "Punti uniti",
    "Created report": "Rapporto creato",
    "Requested video": "Video richiesto",
    "Download signal": "Scarica segnale",
    "See on map": "Vedi sulla mappa",
    Yearly: "Annuale",
    Monthly: "Mensile",
    Weekly: "Settimanale",
    "1Y": "1A",
  },
};
