export default {
  device_on_train: {
    "Carbon strip": "Striscia di carbonio",
    "Last connection": "Ultima connessione",
    "Device setting": "Impostazione dispositivo",
    acceleration: "accelerazione",
    "status is": "lo stato è",
    "not received": "non ricevuto",
    received: "ricevuto",
  },
};
